
export class Plan {
  public Name: string = '';
  public PlanNo: string = '';
  public PlanTypeIds: string = '';
  public StartDate: any;
  public EndDate: any;
  public Duration: string = '';
  public Country: string = '';
  public State: string = '';
  public City: string = '';
  public Area: string = '';
  public NumberOfAttendance: string = '';
  public PlanRate: string = '';
  public NonMembers: any = [];
}

export class MembersPlan {
  public Id?: number;
  public PlanId?: number;
  public MemberId?: number;
  public ResponsibilityInPlanIds?: string;
  public EquipmentQuality?: number;
  public EnvironmentProtection?: number;
  public PhysicalState?: number;
  public Stamina?: number;
  public SkillAndTechnique?: number;
  public TeamWork?: number;
  public PublicEquipmentProtection?: number;
  public AverageScore?: number;
  public PrecentOfScore?: number;
}


export class NonMember {
  public Id?: number;
  public PlanId?: number;
  public FirstName?: string;
  public LastName?: string;
  public FatherName?: string;
  public NationalCode?: string;
  public Mobile?: string;
  public CarName?: string;
  public CarPlaque?: string;
  public ResponsibilitiesInPlanIds?: string;
  public Description?: string;
}

