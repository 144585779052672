import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MemberService } from '../../services/member.service';
import { ResponsibilitiesInClubService } from '../../services/responsibilitiesInClub.service';
import { CertificationService } from '../../services/certification.service';
import { SituationService } from '../../services/situation.service';
import { SkillService } from '../../services/skill.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as moment from 'jalali-moment';
import { Member } from '../member.model';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-member-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})

export class MemberEditComponent implements OnInit {
  _id = '';
  // create object of form group
  memberEditForm: FormGroup;
  objForm;
  members: Member[] = [];
  genderText = 'بانو';
  enable = false;
  responsibilitiesInClubIdsSelected: any[] = [];
  responsibilitiesInClub: any[] = [];
  certifications: any[] = [];
  certificationsIdsSelected: any[] = [];
  situations: any[] = [];
  situationsIdsSelected: any[] = [];
  skills: any[] = [];
  skillsIdsSelected: any[] = [];
  messages;
  config = {};

  public previewPath: any;
  uploader: FileUploader | null = null;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;

  constructor(private formBuilder: FormBuilder,
    private memberService: MemberService,
    private responsibilitiesInClubService: ResponsibilitiesInClubService,
    private certificationService: CertificationService,
    private situationService: SituationService,
    private skillService: SkillService,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer) {
  }

  ngOnInit() {

    this.createForm();

    this.getResponsibilitiesInClub();
    this.getCertifications();
    this.getSituations();
    this.getSkills();

    this.route.params.subscribe(params => {
      this.getMemberById(params.id);
      this.initUploader(params.id);
    });
  }

  initUploader(id: any) {
    this.route.params.subscribe(params => {
      this.previewPath = 'Images/' + params.id + '.jpg?timeStamp=' + Math.floor(Math.random() * 1000);
      let currentUser = this.memberService.currentUserObject;
      this.uploader = new FileUploader(
        <FileUploaderOptions>{
          url: "/api/members/upload/" + id,
          authToken: 'Bearer ' + currentUser.Token,
          headers: [
            { name: "Accept", value: "application/json" }
          ],
          isHTML5: true,
          //allowedMimeType: ["image/jpeg", "image/png", "application/pdf", "application/msword", "application/zip"]
          allowedMimeType: ["image/jpeg"],
          allowedFileType: [
            //"application",
            "image",
            //"video",
            //"audio",
            //"pdf",
            //"compress",
            //"doc",
            //"xls",
            //"ppt"
          ],
          removeAfterUpload: true,
          autoUpload: false,
          maxFileSize: 1 * 1024 * 1024
        }
      );
      this.uploader.onAfterAddingFile = (fileItem) => {
        this.previewPath = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
      }
    });
  }

  onChange() {
    if (this.genderText === 'بانو') {
      this.genderText = 'آقا';
    }
    else {
      this.genderText = 'بانو';
    }
  }

  createForm() {

    this.memberEditForm = this.formBuilder.group({
      'Gender': new FormControl(false),
      'FirstName': new FormControl('', [Validators.required]),
      'LastName': new FormControl('', [Validators.required]),
      'Email': new FormControl(null),
      'FatherName': new FormControl(null),
      'NationalCode': new FormControl(null, [Validators.required, Validators.maxLength(10)]),
      'IdNumber': new FormControl(),
      'BirthDate': new FormControl(null),
      'BloodGroup': new FormControl(),
      'Job': new FormControl(),
      'Country': new FormControl(),
      'State': new FormControl(),
      'City': new FormControl(),
      'Address': new FormControl(),
      'Mobile': new FormControl(),
      'MembershipDate': new FormControl(null),
      'EndDateOfInsurance': new FormControl(null),
      'InsuranceNo': new FormControl(),
      'EducationLevel': new FormControl(),
      'ResponsibilitiesInClubIds': new FormControl(''),
      'CertificationIds': new FormControl(''),
      'SituationIds': new FormControl(''),
      'SkillIds': new FormControl(''),
      'EndDateOfTuition': new FormControl(null),
      'Password': new FormControl()
    });
  }

  arrayToString(arrayObj: any) {
    var result = arrayObj.map(a => a.Id);
    return result.join();
  }

  jalaliToMiladi(date: any) {
    return moment(date, 'jYYYY/jMM/jDD HH:mm').format('YYYY-MM-DD HH:mm:ss');
  }

  convertToJSON(arrayObj: any) {
    return JSON.parse(arrayObj);
  }

  getResponsibilitiesInClub() {
    this.responsibilitiesInClubService.getResponsibilitiesInClub().subscribe(
      x => this.responsibilitiesInClub = x
    );
  }

  getCertifications() {
    this.certificationService.getCertifications().subscribe(
      x => this.certifications = x
    );
  }

  getSituations() {
    this.situationService.getSituations().subscribe(
      x => this.situations = x
    );
  }

  getSkills() {
    this.skillService.getSkills().subscribe(
      x => this.skills = x
    );
  }

  getMembers() {
    this.memberService.getMembers().subscribe(
      x => this.members = x
    );
  }

  getSelectedResponsibilities(ids: any) {
    for (var i = 0; i < ids.split(',').length; i++) {
      this.responsibilitiesInClubIdsSelected.push(this.responsibilitiesInClub.find(x => x.Id == Number(ids.split(',')[i])));
    }
    return this.responsibilitiesInClubIdsSelected;
  }

  getSelectedCertifications(ids: any) {
    for (var i = 0; i < ids.split(',').length; i++) {
      this.certificationsIdsSelected.push(this.certifications.find(x => x.Id == Number(ids.split(',')[i])));
    }
    return this.certificationsIdsSelected;
  }

  getSelectedSituations(ids: any) {
    for (var i = 0; i < ids.split(',').length; i++) {
      this.situationsIdsSelected.push(this.situations.find(x => x.Id == Number(ids.split(',')[i])));
    }
    return this.situationsIdsSelected;
  }

  getSelectedSkills(ids: any) {
    for (var i = 0; i < ids.split(',').length; i++) {
      this.skillsIdsSelected.push(this.skills.find(x => x.Id == Number(ids.split(',')[i])));
    }
    return this.skillsIdsSelected;
  }

  getMemberById(id: number) {

    this.memberService.getMemberById(id).subscribe((data: any) => {
      this._id = data.Id;
      this.memberEditForm.controls['Gender'].setValue(data.Gender);
      this.memberEditForm.controls['FirstName'].setValue(data.FirstName);
      this.memberEditForm.controls['LastName'].setValue(data.LastName);
      this.memberEditForm.controls['Email'].setValue(data.Email);
      this.memberEditForm.controls['FatherName'].setValue(data.FatherName);
      this.memberEditForm.controls['NationalCode'].setValue(data.NationalCode);
      this.memberEditForm.controls['IdNumber'].setValue(data.IdNumber);
      if (data.BirthDate) {
        this.memberEditForm.controls['BirthDate'].setValue(moment(data.BirthDate, 'YYYY/MM/DD').locale('fa').format('YYYY-MM-DD'));
      }
      this.memberEditForm.controls['BloodGroup'].setValue(data.BloodGroup);
      this.memberEditForm.controls['Job'].setValue(data.Job);
      this.memberEditForm.controls['Country'].setValue(data.Country);
      this.memberEditForm.controls['State'].setValue(data.State);
      this.memberEditForm.controls['City'].setValue(data.City);
      this.memberEditForm.controls['Address'].setValue(data.Address);
      this.memberEditForm.controls['Mobile'].setValue(data.Mobile);
      if (data.MembershipDate) {
        this.memberEditForm.controls['MembershipDate'].setValue(moment(data.MembershipDate, 'YYYY/MM/DD').locale('fa').format('YYYY-MM-DD'));
      }
      if (data.EndDateOfInsurance) {
        this.memberEditForm.controls['EndDateOfInsurance'].setValue(moment(data.EndDateOfInsurance, 'YYYY/MM/DD').locale('fa').format('YYYY-MM-DD'));
      }
      if (data.EndDateOfTuition) {
        this.memberEditForm.controls['EndDateOfTuition'].setValue(moment(data.EndDateOfTuition, 'YYYY/MM/DD').locale('fa').format('YYYY-MM-DD'));
      }
      this.memberEditForm.controls['InsuranceNo'].setValue(data.InsuranceNo);
      this.memberEditForm.controls['EducationLevel'].setValue(data.EducationLevel);
      if (data.ResponsibilitiesInClubIds) {
        this.memberEditForm.controls['ResponsibilitiesInClubIds'].setValue(this.getSelectedResponsibilities(data.ResponsibilitiesInClubIds));
      }
      if (data.CertificationIds) {
        this.memberEditForm.controls['CertificationIds'].setValue(this.getSelectedCertifications(data.CertificationIds));
      }
      if (data.SituationIds) {
        this.memberEditForm.controls['SituationIds'].setValue(this.getSelectedSituations(data.SituationIds));
      }
      if (data.SkillIds) {
        this.memberEditForm.controls['SkillIds'].setValue(this.getSelectedSkills(data.SkillIds));
      }
      this.memberEditForm.controls['Password'].setValue(null);

      if (data.Gender == false) {
        this.genderText = 'آقا';
        this.enable = false;
      } else {
        this.genderText = 'بانو';
        this.enable = true;
      }

    });
  }

  goBack() {
    this.router.navigateByUrl('/member/list');
  }

  editMember() {

    if (this.memberEditForm.invalid) {
      this.memberEditForm.get('FirstName').markAsTouched();
      this.memberEditForm.get('LastName').markAsTouched();
      this.memberEditForm.get('NationalCode').markAsTouched();
      return;
    }

    this.objForm = this.memberEditForm.getRawValue();

    if (this.memberEditForm.get("ResponsibilitiesInClubIds").value) {
      this.objForm.ResponsibilitiesInClubIds = this.arrayToString(this.memberEditForm.get("ResponsibilitiesInClubIds").value);
    }

    if (this.memberEditForm.get("CertificationIds").value) {
      this.objForm.CertificationIds = this.arrayToString(this.memberEditForm.get("CertificationIds").value);
    }

    if (this.memberEditForm.get("SituationIds").value) {
      this.objForm.SituationIds = this.arrayToString(this.memberEditForm.get("SituationIds").value);
    }

    if (this.memberEditForm.get("SkillIds").value) {
      this.objForm.SkillIds = this.arrayToString(this.memberEditForm.get("SkillIds").value);
    }

    if (this.memberEditForm.get("BirthDate").value) {
      this.objForm.BirthDate = this.jalaliToMiladi(this.memberEditForm.get("BirthDate").value);
    }

    if (this.memberEditForm.get("MembershipDate").value) {
      this.objForm.MembershipDate = this.jalaliToMiladi(this.memberEditForm.get("MembershipDate").value);
    }

    if (this.memberEditForm.get("EndDateOfInsurance").value) {
      this.objForm.EndDateOfInsurance = this.jalaliToMiladi(this.memberEditForm.get("EndDateOfInsurance").value);
    }

    if (this.memberEditForm.get("EndDateOfTuition").value) {
      this.objForm.EndDateOfTuition = this.jalaliToMiladi(this.memberEditForm.get("EndDateOfTuition").value);
    }

    if (this.memberEditForm.get("Password").value) {
      this.objForm.Password = this.memberEditForm.get("Password").value;
    }

    this.objForm.Gender = this.enable;

    this.route.params.subscribe(params => {
      if (this._id) {
        this.objForm.id = this._id;
      }

      this.memberService.editMember(params.id, this.objForm).subscribe(
        result => {
          console.log(result);
          this.memberEditForm.reset();
          this.genderText = 'آقا';
          this.enable = false;
          this.toastr.success(this.objForm.FirstName + ' ' + this.objForm.LastName + ' با موفقیت ویرایش شد.', 'موفقیت');
          this.router.navigateByUrl('/member/list');
        },
        error => {
          //this.errors = error;
          this.toastr.error('ویرایش ' + this.objForm.FirstName + ' ' + this.objForm.LastName + ' با شکست مواجه شد.', 'خطا');
        },
        () => {
          // 'onCompleted' callback.
          // No errors, route to new page here
        }
      );
    });
  }
}
