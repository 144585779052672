import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PlanComponent } from './list/list.component';
import { PlanAddComponent } from './add/add.component';
import { PlanEditComponent } from './edit/edit.component';
import { PlanAssessmentComponent } from './assessment/assessment.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: PlanComponent,
        data: {
          title: 'List of Plans'
        }
      },
      {
        path: 'add',
        component: PlanAddComponent,
        data: {
          title: 'Add Plans'
        }
      },
      {
        path: 'edit/:id',
        component: PlanEditComponent,
        data: {
          title: 'Edit Plans'
        }
      },
      {
        path: 'assessment/:id',
        component: PlanAssessmentComponent,
        data: {
          title: 'Assessment Plans'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PlanRoutingModule { }
