import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MembersPlanService {

  constructor(private httpClient: HttpClient) { }

  membersPlan: any;

  getMembersPlan(): Observable<any> {
    return this.httpClient.get<any>('/api/membersPlans')
      .pipe(map(res => res));
  }

  getMembersPlanById(id: number) {
    return this.httpClient.get('/api/membersPlans/' + id)
      .pipe(map(res => res));
  }

  saveMembersPlan(membersPlan: any) {
    return this.httpClient.post<any>('/api/membersPlans', membersPlan).map(
      res => res
    );
  }

  assessmentMembersPlan(membersPlan: any) {
    return this.httpClient.put<any>('/api/membersPlans', membersPlan)
      .pipe(map(res => res));
  }

  deleteMembersPlan(id: number) {
    return this.httpClient.delete<any>('/api/membersPlans/' + id)
      .pipe(map(res => res));
  }
}
