import { PlanService } from '../../services/plan.service';
import { MemberService } from '../../services/member.service';
import { PlanTypesService } from '../../services/planTypes.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { ActivatedRoute, Router } from '@angular/router';
import { Plan, MembersPlan, NonMember } from '../plan.model';
import * as moment from 'jalali-moment';
import { Role } from '../../member/member.model';
import { ResponsibilitiesInPlanService } from '../../services/responsibilitiesInPlan.service';

@Component({
  selector: 'app-plan-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})

export class PlanEditComponent implements OnInit {
  _id = '';
  // create object of form group
  planEditForm: FormGroup;
  enable = false;
  planTypes;
  objForm;
  messages;
  planTypesIdsSelected: any[] = [];
  finalPlans: Plan[] = [];
  rows: any;
  loadingIndicator = true;
  reorderable = true;
  @ViewChild('firstTable', { static: true }) memberTable: DatatableComponent;
  @ViewChild('secondTable', { static: true }) memberPlanTable: DatatableComponent;
  temp = [];
  selecteds = [];
  membersPlans = [];
  config = {};
  memberRole: string;

  planEditNonMemberForm: FormGroup;
  active = 0;
  nonMembers: NonMember[] = [];
  responsibilitiesInPlanIdsSelected: any[] = [];
  responsibilitiesInPlan: any[] = [];
  selectedNonMembers: NonMember[] = [];
  nonMemberRow: NonMember;

  constructor(private formBuilder: FormBuilder,
    private planService: PlanService,
    private memberService: MemberService,
    private planTypesService: PlanTypesService,
    private responsibilitiesInPlanService: ResponsibilitiesInPlanService,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.memberRole = this.memberService.whatsRole();
    if (this.memberRole != Role.Admin) {
      this.memberService.logout();
    }
    this.getResponsibilitiesInPlan();
    this.getPlanTypes();
    this.createForm();
    this.route.params.subscribe(params => {
      this.getPlanById(params.id);
    });
  }

  getResponsibilitiesInPlan() {
    this.responsibilitiesInPlanService.getResponsibilitiesInPlan().subscribe(
      x => this.responsibilitiesInPlan = x
    );
  }

  getSelectedResponsibilities(ids: any) {
    for (var i = 0; i < ids.split(',').length; i++) {
      this.responsibilitiesInPlanIdsSelected.push(this.responsibilitiesInPlan.find(x => x.Id == Number(ids.split(',')[i])));
    }
    return this.responsibilitiesInPlanIdsSelected;
  }

  addNonMembersToList() {

    if (!this.nonMemberRow) {
      var non = new NonMember();
      non.FirstName = this.planEditNonMemberForm.get("FirstName").value;
      non.LastName = this.planEditNonMemberForm.get("LastName").value;
      non.FatherName = this.planEditNonMemberForm.get("FatherName").value;
      non.NationalCode = this.planEditNonMemberForm.get("NationalCode").value;
      non.CarName = this.planEditNonMemberForm.get("CarName").value;
      non.CarPlaque = this.planEditNonMemberForm.get("CarPlaque").value;
      non.Mobile = this.planEditNonMemberForm.get("Mobile").value;
      non.Description = this.planEditNonMemberForm.get("Description").value;
      non.ResponsibilitiesInPlanIds = this.arrayToString(this.planEditNonMemberForm.get("ResponsibilityInPlanIds").value);

      this.selectedNonMembers.push(non);
    } else {

      let objIndex = this.selectedNonMembers.findIndex(obj => (obj.FirstName + ' ' + obj.LastName) == (this.nonMemberRow.FirstName + ' ' + this.nonMemberRow.LastName));

      //Update object's name property.
      this.selectedNonMembers[objIndex].FirstName = this.planEditNonMemberForm.get("FirstName").value;
      this.selectedNonMembers[objIndex].LastName = this.planEditNonMemberForm.get("LastName").value;
      this.selectedNonMembers[objIndex].FatherName = this.planEditNonMemberForm.get("FatherName").value;
      this.selectedNonMembers[objIndex].NationalCode = this.planEditNonMemberForm.get("NationalCode").value;
      this.selectedNonMembers[objIndex].CarName = this.planEditNonMemberForm.get("CarName").value;
      this.selectedNonMembers[objIndex].CarPlaque = this.planEditNonMemberForm.get("CarPlaque").value;
      this.selectedNonMembers[objIndex].Mobile = this.planEditNonMemberForm.get("Mobile").value;
      this.selectedNonMembers[objIndex].Description = this.planEditNonMemberForm.get("Description").value;
      this.selectedNonMembers[objIndex].ResponsibilitiesInPlanIds = this.arrayToString(this.planEditNonMemberForm.get("ResponsibilityInPlanIds").value);

      this.nonMemberRow = null;
    }

    this.selectedNonMembers = [...this.selectedNonMembers];

    this.planEditNonMemberForm.patchValue({
      FirstName: '',
      LastName: '',
      FatherName: '',
      NationalCode: '',
      CarName: '',
      CarPlaque: '',
      Mobile: '',
      Description: '',
      ResponsibilitiesInPlanIds: null
    });
    this.responsibilitiesInPlanIdsSelected = [];
  }

  editNonMember(row) {
    this.responsibilitiesInPlanIdsSelected = [];
    this.planEditNonMemberForm.patchValue({
      FirstName: row.FirstName,
      LastName: row.LastName,
      FatherName: row.FatherName,
      NationalCode: row.NationalCode,
      CarName: row.CarName,
      CarPlaque: row.CarPlaque,
      Mobile: row.Mobile,
      Description: row.Description,
      ResponsibilitiesInPlanIds: row.ResponsibilitiesInPlanIds
    });
    this.nonMemberRow = row;
    this.responsibilitiesInPlanIdsSelected = [...this.getSelectedResponsibilities(row.ResponsibilitiesInPlanIds)];
  }

  deleteNonMember(row) {
    this.selectedNonMembers = this.nonMemberRemove(this.selectedNonMembers, row.FirstName + ' ' + row.LastName);
  }

  nonMemberRemove(array, name) {
    return array.filter(function (element) {
      return (element.FirstName + ' ' + element.LastName != name);
    });
  }

  public onCheckboxChange(event: any, row: any): void {
    if (this.getChecked(row) === false) {
      // add
      let dateTime = Date.now();

      if (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) < dateTime) {
        swal.fire({
          title: 'مشکل بیمه و شهریه',
          text: "بیمه این عضو منقضی و شهریه وی پرداخت نگردیده است. آیا مجاز به شرکت در برنامه می باشند؟",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0CC27E',
          cancelButtonColor: '#FF586B',
          confirmButtonText: 'بله',
          cancelButtonText: 'خیر',
          confirmButtonClass: 'btn btn-success btn-raised mr-5',
          cancelButtonClass: 'btn btn-danger btn-raised',
          buttonsStyling: false
        }).then((res) => {
          if (res.value) {
            this.selecteds.push(row);

            this.selecteds = [...this.selecteds];
          } else {

            if (this.selecteds.length > 0) {
              // remove
              for (let i = 0; i < this.selecteds.length; i++) {
                if (this.selecteds[i].Id === row.Id) {
                  this.selecteds.splice(i, 1);
                  break;
                }
              }
            }
            else {
              this.selecteds = this.selecteds;
            }
            this.selecteds = [...this.selecteds];
            event.target.checked = false;
          }

        })
      }

      if (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) > dateTime) {
        swal.fire({
          title: 'مشکل بیمه',
          text: "بیمه این عضو منقضی شده است. آیا مجاز به شرکت در برنامه می باشند؟",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0CC27E',
          cancelButtonColor: '#FF586B',
          confirmButtonText: 'بله',
          cancelButtonText: 'خیر',
          confirmButtonClass: 'btn btn-success btn-raised mr-5',
          cancelButtonClass: 'btn btn-danger btn-raised',
          buttonsStyling: false
        }).then((res) => {
          if (res.value) {
            this.selecteds.push(row);

            this.selecteds = [...this.selecteds];
          } else {
            if (this.selecteds.length > 0) {
              // remove
              for (let i = 0; i < this.selecteds.length; i++) {
                if (this.selecteds[i].Id === row.Id) {
                  this.selecteds.splice(i, 1);
                  break;
                }
              }
            }
            else {
              this.selecteds = this.selecteds;
            }
            this.selecteds = [...this.selecteds];
            event.target.checked = false;
          }

        })
      }

      if (Date.parse(row.EndDateOfInsurance) > dateTime && Date.parse(row.EndDateOfTuition) < dateTime) {
        swal.fire({
          title: 'مشکل شهریه',
          text: "شهریه این عضو پرداخت نگردیده است. آیا مجاز به شرکت در برنامه می باشند؟",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0CC27E',
          cancelButtonColor: '#FF586B',
          confirmButtonText: 'بله',
          cancelButtonText: 'خیر',
          confirmButtonClass: 'btn btn-success btn-raised mr-5',
          cancelButtonClass: 'btn btn-danger btn-raised',
          buttonsStyling: false
        }).then((res) => {
          if (res.value) {
            this.selecteds.push(row);

            this.selecteds = [...this.selecteds];
          } else {
            if (this.selecteds.length > 0) {
              // remove
              for (let i = 0; i < this.selecteds.length; i++) {
                if (this.selecteds[i].Id === row.Id) {
                  this.selecteds.splice(i, 1);
                  break;
                }
              }
            }
            else {
              this.selecteds = this.selecteds;
            }
            this.selecteds = [...this.selecteds];
            event.target.checked = false;

          }

        })
      }

      if (Date.parse(row.EndDateOfInsurance) > dateTime && Date.parse(row.EndDateOfTuition) > dateTime) {
        //this.selecteds.splice(0, 0, row);
        this.selecteds.push(row);

        this.selecteds = [...this.selecteds];


        //this.selecteds = [...this.selecteds];
      }

    } else {
      // remove
      for (let i = 0; i < this.selecteds.length; i++) {
        if (this.selecteds[i].Id === row.Id) {
          this.selecteds.splice(i, 1);
          this.selecteds = [...this.selecteds];
          break;
        }
      }
    }
  }

  public getChecked(row: any): boolean {
    // autofill checked
    const item = this.selecteds.filter((e) => e.Id === row.Id);
    return item.length > 0 ? true : false;
  }

  createForm() {

    this.planEditForm = this.formBuilder.group({
      Name: new FormControl('', [Validators.required]),
      PlanNo: new FormControl('ش ج-10-', [Validators.required]),
      PlanTypeIds: new FormControl('', [Validators.required]),
      StartDate: new FormControl(null, [Validators.required]),
      EndDate: new FormControl(null, [Validators.required]),
      Duration: new FormControl(null, [Validators.required]),
      NumberOfAttendance: new FormControl(null, [Validators.required]),
      Country: new FormControl(),
      State: new FormControl(),
      City: new FormControl(),
      Area: new FormControl(),
      PlanRate: new FormControl(null, [Validators.required]),
      IssueDate: new FormControl(null, [Validators.required]),
      MembersPlans: this.formBuilder.array([]),
      NonMembers: this.formBuilder.array([])
    });

    this.planEditNonMemberForm = this.formBuilder.group({
      FirstName: new FormControl(),
      LastName: new FormControl(),
      FatherName: new FormControl(),
      NationalCode: new FormControl(),
      CarName: new FormControl(),
      CarPlaque: new FormControl(),
      Mobile: new FormControl(),
      ResponsibilityInPlanIds: new FormControl(),
      Description: new FormControl(),
    });
  }

  arrayToString(arrayObj: any) {
    var result = arrayObj.map(a => a.Id);
    return result.join();
  }

  jalaliToMiladi(date: any) {
    return moment(date, 'jYYYY/jMM/jDD HH:mm').format('YYYY-MM-DD HH:mm:ss');
  }

  convertToJSON(arrayObj: any) {
    return JSON.parse(arrayObj);
  }

  getPlanTypes() {
    this.planTypesService.getPlanTypes().subscribe(
      x => {
        this.planTypes = x;
      }
    );
  }

  getPlans() {
    this.planService.getPlans().subscribe(
      x => this.finalPlans = x
    );
  }

  getSelectedPlanTypes(ids: any) {
    for (var i = 0; i < ids.split(',').length; i++) {
      this.planTypesIdsSelected.push(this.planTypes.find(x => x.Id == Number(ids.split(',')[i])));
    }
    return this.planTypesIdsSelected;
  }

  removeMember(id) {
    this.selecteds = this.selecteds.filter(i => i.Id != id);
    this.selecteds = [...this.selecteds];
  }

  getMembers() {
    this.memberService.getMembers().subscribe(members => {
      this.rows = members;
      this.temp = [...members];
    },
      error => {
        //this.errors = error;
        //this.toastr.error('ویرایش ' + this.objForm.Name + ' با شکست مواجه شد.', 'خطا');
      },
      () => {
        //this.memberTable.selected = this.selecteds;
      });
  }

  getPlanById(id: number) {

    this.planService.getPlanById(id).subscribe((data: any) => {

      console.log(data);
      this._id = data.Id;
      this.planEditForm.controls['Name'].setValue(data.Name);
      this.planEditForm.controls['PlanNo'].setValue(data.PlanNo);
      this.planEditForm.controls['PlanTypeIds'].setValue(this.getSelectedPlanTypes(data.PlanTypeIds));
      this.planEditForm.controls['StartDate'].setValue(moment(data.StartDate, 'YYYY/MM/DD').locale('fa').format('YYYY-MM-DD'));
      this.planEditForm.controls['EndDate'].setValue(moment(data.EndDate, 'YYYY/MM/DD').locale('fa').format('YYYY-MM-DD'));
      this.planEditForm.controls['Duration'].setValue(data.Duration);
      this.planEditForm.controls['NumberOfAttendance'].setValue(data.NumberOfAttendance);
      this.planEditForm.controls['Country'].setValue(data.Country);
      this.planEditForm.controls['State'].setValue(data.State);
      this.planEditForm.controls['City'].setValue(data.City);
      this.planEditForm.controls['Area'].setValue(data.Area);
      this.planEditForm.controls['PlanRate'].setValue(data.PlanRate);
      this.planEditForm.controls['IssueDate'].setValue(moment(data.IssueDate, 'YYYY/MM/DD').locale('fa').format('YYYY-MM-DD'));

      if (data.MembersPlans) {
        this.membersPlans = data.MembersPlans;
        for (var i = 0; i < data.MembersPlans.length; i++) {
          var member = data.MembersPlans[i].Member;
          this.selecteds.push(member);
        }

        this.getMembers();

      }

      if (data.NonMembers) {
        this.nonMembers = data.NonMembers;
        for (var i = 0; i < data.NonMembers.length; i++) {
          var nonMember = data.NonMembers[i];
          this.selectedNonMembers.push(nonMember);
        }

      }
    },
      err => console.error('getPlanById() got an error: ' + err));
  }

  goBack() {
    this.router.navigateByUrl('/plan/list');
  }

  editPlan() {

    if (this.planEditForm.invalid) {
      this.planEditForm.get('Name').markAsTouched();
      this.planEditForm.get('PlanNo').markAsTouched();
      this.planEditForm.get('PlanTypeIds').markAsTouched();
      this.planEditForm.get('StartDate').markAsTouched();
      this.planEditForm.get('EndDate').markAsTouched();
      this.planEditForm.get('Duration').markAsTouched();
      this.planEditForm.get('NumberOfAttendance').markAsTouched();
      this.planEditForm.get('PlanRate').markAsTouched();
      this.planEditForm.get('IssueDate').markAsTouched();

      return;
    }

    this.objForm = this.planEditForm.getRawValue();

    if (this.planEditForm.get("PlanTypeIds").value) {
      this.objForm.PlanTypeIds = this.arrayToString(this.planEditForm.get("PlanTypeIds").value);
    }

    if (this.planEditForm.get("StartDate").value) {
      this.objForm.StartDate = this.jalaliToMiladi(this.planEditForm.get("StartDate").value);
    }

    if (this.planEditForm.get("EndDate").value) {
      this.objForm.EndDate = this.jalaliToMiladi(this.planEditForm.get("EndDate").value);
    }

    if (this.planEditForm.get("Duration").value) {
      this.objForm.Duration = +(this.planEditForm.get("Duration").value);
    }

    if (this.planEditForm.get("NumberOfAttendance").value) {
      this.objForm.NumberOfAttendance = +(this.planEditForm.get("NumberOfAttendance").value);
    }

    if (this.planEditForm.get("PlanRate").value) {
      this.objForm.PlanRate = +(this.planEditForm.get("PlanRate").value);
    }

    if (this.planEditForm.get("Country").value) {
      this.objForm.Country = (this.planEditForm.get("Country").value);
    }

    if (this.planEditForm.get("State").value) {
      this.objForm.State = (this.planEditForm.get("State").value);
    }

    if (this.planEditForm.get("IssueDate").value) {
      this.objForm.IssueDate = this.jalaliToMiladi(this.planEditForm.get("IssueDate").value);
    }

    if (this.selecteds.length > 0) {
      for (var i = 0; i < this.selecteds.length; i++) {
        var membersPlan = new MembersPlan();

        //if (this.membersPlans[i].id) {
        //  membersPlan.id = this.membersPlans[i].id;
        //}

        membersPlan.PlanId = 0;
        membersPlan.MemberId = this.selecteds[i].Id;
        membersPlan.ResponsibilityInPlanIds = "";
        membersPlan.EquipmentQuality = 0;
        membersPlan.EnvironmentProtection = 0;
        membersPlan.PhysicalState = 0;
        membersPlan.Stamina = 0;
        membersPlan.SkillAndTechnique = 0;
        membersPlan.TeamWork = 0;
        membersPlan.PublicEquipmentProtection = 0;
        membersPlan.AverageScore = 0;
        membersPlan.PrecentOfScore = 0;
        this.objForm.MembersPlans.push(membersPlan);
      }
    }

    if (this.selectedNonMembers.length > 0) {
      for (var i = 0; i < this.selectedNonMembers.length; i++) {
        var non = new NonMember();
        non.PlanId = 0;
        non.FirstName = this.selectedNonMembers[i].FirstName;
        non.LastName = this.selectedNonMembers[i].LastName;
        non.FatherName = this.selectedNonMembers[i].FatherName;
        non.NationalCode = this.selectedNonMembers[i].NationalCode;
        non.CarName = this.selectedNonMembers[i].CarName;
        non.CarPlaque = this.selectedNonMembers[i].CarPlaque;
        non.Mobile = this.selectedNonMembers[i].Mobile;
        non.Description = this.selectedNonMembers[i].Description;
        non.ResponsibilitiesInPlanIds = this.selectedNonMembers[i].ResponsibilitiesInPlanIds;
        this.objForm.NonMembers.push(non);
      }
    }

    this.route.params.subscribe(params => {
      //if (this._id) {
      //this.objForm.id = this._id;
      //}

      this.planService.editPlan(params.id, this.objForm).subscribe(
        result => {
          if (result != null) {
            if (result['statusCode'] == 2021) {
              this.toastr.error('این برنامه ارزیابی شده و قابل حذف یا تغییر نمی باشد.', 'خطا', {
              });
              return;
            }
          }
          this.planEditForm.reset();
          this.selecteds = [];
          this.enable = false;
          this.toastr.success(this.objForm.Name + ' با موفقیت ویرایش شد.', 'موفقیت');
          this.router.navigateByUrl('/plan/list');
        },
        error => {
          //this.errors = error;
          this.toastr.error('ویرایش ' + this.objForm.Name + ' با شکست مواجه شد.', 'خطا');
        },
        () => {
          // 'onCompleted' callback.
          // No errors, route to new page here
        }
      );
    });
  }

  updateFilter(event) {
    const val = event.target.value;
    // filter our data
    const temp = this.temp.filter(function (d) {
      const name = d.FirstName + ' ' + d.LastName;
      return name.indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    //this.memberTable.offset = 0;
  }

  checkConditions(row) {
    let dateTime = Date.now();

    if (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) < dateTime) {
      return {
        'insurance-and-tuition': (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) < dateTime)
      }
    }

    if (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) > dateTime) {
      return {
        'insurance': (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) > dateTime)
      }
    }

    if (true) {
      return {
        'tuition': (Date.parse(row.EndDateOfInsurance) > dateTime && Date.parse(row.EndDateOfTuition) < dateTime)
      }
    }
  }

}
