import { MemberService } from '../../services/member.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { saveAs } from 'file-saver';
import * as moment from 'jalali-moment';

@Component({
  selector: 'app-member-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})

export class MemberComponent {
  name: string = "Hello Member";
  memberForm: any;
  rows: any;
  p: number = 1;
  loadingIndicator = true;
  reorderable = true;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  temp = [];

  constructor(
    private fb: FormBuilder,
    private memberService: MemberService,
    public toastr: ToastrService) {
  }

  ngOnInit() {
    this.getMembers();
  }

  getMembers() {
    this.memberService.getMembers().subscribe(members => {
      this.rows = members;
      this.temp = [...members];
      setTimeout(() => {
        this.loadingIndicator = false;
      }, 1000);
    });
  }

  updateFilter(event) {
    const val = event.target.value;
    // filter our data
    const temp = this.temp.filter(function (d) {
      const name = d.FirstName + ' ' + d.LastName;
      return name.indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  deleteMember(id: number, firstName: string, lastName: string) {
    swal.fire({
      title: 'حذف آیتم',
      text: "آیا از حدف این آیتم مطمین هستید؟",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0CC27E',
      cancelButtonColor: '#FF586B',
      confirmButtonText: 'بله',
      cancelButtonText: 'خیر',
      confirmButtonClass: 'btn btn-success btn-raised mr-5',
      cancelButtonClass: 'btn btn-danger btn-raised',
      buttonsStyling: false
    }).then((res) => {
      if (res.value) {
        this.memberService.deleteMember(id).subscribe(
          result => {
            console.log(result);
            this.toastr.success(firstName + ' ' + lastName + ' با موفقیت حذف شد.', 'موفقیت');
            this.getMembers();
          },
          error => {
            //this.errors = error;
            this.toastr.error('حذف ' + firstName + ' ' + lastName + ' با شکست مواجه شد.', 'خطا');
          },
          () => {
            // 'onCompleted' callback.
            // No errors, route to new page here
          }
        );
      } else {
        // handle cancel
      }

    })
  }

  checkConditions(row) {
    let dateTime = Date.now();

    if (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) < dateTime) {
      return {
        'insurance-and-tuition': (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) < dateTime)
      }
    }

    if (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) > dateTime) {
      return {
        'insurance': (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) > dateTime)
      }
    }

    if (true) {
      return {
        'tuition': (Date.parse(row.EndDateOfInsurance) > dateTime && Date.parse(row.EndDateOfTuition) < dateTime)
      }
    }
  }

  generateCard(id: number) {
    let current_datetime = new Date();
    let formatted_date = current_datetime.getFullYear() + "/" + (current_datetime.getMonth() + 1) + "/" + current_datetime.getDate();
    let now = moment(formatted_date, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD');

    this.memberService.generateCard(id).subscribe(blob => {
      saveAs(blob, 'Card-' + now + '.pdf', {
        type: 'application/pdf',
        autoBom: true
      });
    });
  }

}
