import { PlanService } from '../../services/plan.service';
import { MemberService } from '../../services/member.service';
import { PlanTypesService } from '../../services/planTypes.service';
import { ResponsibilitiesInPlanService } from '../../services/responsibilitiesInPlan.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DatatableComponent } from "@swimlane/ngx-datatable";
//import { NgbNav, NgbNavItem, NgbNavLink, NgbNavContent } from "@ng-bootstrap/ng-bootstrap";

import * as moment from 'jalali-moment';
import { Role } from '../../member/member.model';

@Component({
  selector: 'app-plan-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})

export class PlanAddComponent implements OnInit {
  // create object of form group
  planAddForm: FormGroup;
  enable = false;
  planTypes;
  objForm;
  messages;
  rows: any;
  loadingIndicator = true;
  reorderable = true;
  @ViewChild('firstTable', { static: false }) table: DatatableComponent;
  @ViewChild('secondTable', { static: false }) memberPlanTable: DatatableComponent;
  temp = [];
  selecteds: any[] = [];
  config = {};
  planMembers = [];
  memberRole: string;

  planAddNonMemberForm: FormGroup;
  active = 0;
  nonMembers: NonMember[] = [];
  responsibilitiesInPlanIdsSelected: any[] = [];
  responsibilitiesInPlan: any[] = [];
  selectedNonMembers: NonMember[] = [];
  nonMemberRow: NonMember;

  constructor(private formBuilder: FormBuilder,
    private planService: PlanService,
    private memberService: MemberService,
    private responsibilitiesInPlanService: ResponsibilitiesInPlanService,
    private planTypesService: PlanTypesService,
    public toastr: ToastrService) {
  }

  ngOnInit() {
    this.memberRole = this.memberService.whatsRole();
    if (this.memberRole != Role.Admin) {
      this.memberService.logout();
    }
    this.getPlanTypes();
    this.getResponsibilitiesInPlan();
    this.getMembers();
    this.createForm();
  }

  getResponsibilitiesInPlan() {
    this.responsibilitiesInPlanService.getResponsibilitiesInPlan().subscribe(
      x => this.responsibilitiesInPlan = x
    );
  }

  getSelectedResponsibilities(ids: any) {
    this.responsibilitiesInPlanIdsSelected = [];

    for (var i = 0; i < ids.split(',').length; i++) {
      let item = this.responsibilitiesInPlan.find(x => x.Id == Number(ids.split(',')[i]));
      this.responsibilitiesInPlanIdsSelected.push(item);
    }
    return this.responsibilitiesInPlanIdsSelected;
  }

  addNonMembersToList() {

    if (!this.nonMemberRow) {

      //Add
      var non = new NonMember();
      non.firstName = this.planAddNonMemberForm.get("FirstName").value;
      non.lastName = this.planAddNonMemberForm.get("LastName").value;
      non.fatherName = this.planAddNonMemberForm.get("FatherName").value;
      non.nationalCode = this.planAddNonMemberForm.get("NationalCode").value;
      non.carName = this.planAddNonMemberForm.get("CarName").value;
      non.carPlaque = this.planAddNonMemberForm.get("CarPlaque").value;
      non.mobile = this.planAddNonMemberForm.get("Mobile").value;
      non.description = this.planAddNonMemberForm.get("Description").value;
      non.responsibilitiesInPlanIds = this.arrayToString(this.planAddNonMemberForm.get("ResponsibilityInPlanIds").value.map(({ Id }) => Id));

      this.selectedNonMembers.push(non);
    } else {

      //Edit
      let objIndex = this.selectedNonMembers.findIndex(obj => (obj.firstName + ' ' + obj.lastName) == (this.nonMemberRow.firstName + ' ' + this.nonMemberRow.lastName));

      this.selectedNonMembers[objIndex].firstName = this.planAddNonMemberForm.get("FirstName").value;
      this.selectedNonMembers[objIndex].lastName = this.planAddNonMemberForm.get("LastName").value;
      this.selectedNonMembers[objIndex].fatherName = this.planAddNonMemberForm.get("FatherName").value;
      this.selectedNonMembers[objIndex].nationalCode = this.planAddNonMemberForm.get("NationalCode").value;
      this.selectedNonMembers[objIndex].carName = this.planAddNonMemberForm.get("CarName").value;
      this.selectedNonMembers[objIndex].carPlaque = this.planAddNonMemberForm.get("CarPlaque").value;
      this.selectedNonMembers[objIndex].mobile = this.planAddNonMemberForm.get("Mobile").value;
      this.selectedNonMembers[objIndex].description = this.planAddNonMemberForm.get("Description").value;
      this.selectedNonMembers[objIndex].responsibilitiesInPlanIds = this.arrayToString(this.planAddNonMemberForm.get("ResponsibilityInPlanIds").value.map(({ Id }) => Id));

      this.nonMemberRow = null;
    }

    this.selectedNonMembers = [...this.selectedNonMembers];

    this.planAddNonMemberForm.patchValue({
      FirstName: '',
      LastName: '',
      FatherName: '',
      NationalCode: '',
      CarName: '',
      CarPlaque: '',
      Mobile: '',
      Description: '',
      ResponsibilityInPlanIds: null
    });
    this.responsibilitiesInPlanIdsSelected = [];
  }

  editNonMember(row) {

    this.planAddNonMemberForm.patchValue({
      FirstName: row.firstName,
      LastName: row.lastName,
      FatherName: row.fatherName,
      NationalCode: row.nationalCode,
      CarName: row.carName,
      CarPlaque: row.carPlaque,
      Mobile: row.mobile,
      Description: row.description,
      ResponsibilityInPlanIds: row.responsibilitiesInPlanIds
    });
    this.nonMemberRow = row;
    this.responsibilitiesInPlanIdsSelected = [...this.getSelectedResponsibilities(row.responsibilitiesInPlanIds)];
  }

  deleteNonMember(row) {
    this.selectedNonMembers = this.nonMemberRemove(this.selectedNonMembers, row.FirstName + ' ' + row.LastName);
  }

  nonMemberRemove(array, name) {
    return array.filter(function (element) {
      return (element.FirstName + ' ' + element.LastName != name);
    });
  }

  public onCheckboxChange(event: any, row: any): void {
    if (this.getChecked(row) === false) {
      // add
      let dateTime = Date.now();

      if (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) < dateTime) {
        swal.fire({
          title: 'مشکل بیمه و شهریه',
          text: "بیمه این عضو منقضی و شهریه وی پرداخت نگردیده است. آیا مجاز به شرکت در برنامه می باشند؟",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0CC27E',
          cancelButtonColor: '#FF586B',
          confirmButtonText: 'بله',
          cancelButtonText: 'خیر',
          confirmButtonClass: 'btn btn-success btn-raised mr-5',
          cancelButtonClass: 'btn btn-danger btn-raised',
          buttonsStyling: false
        }).then((res) => {
          if (res.value) {
            this.selecteds.push(row);

            this.selecteds = [...this.selecteds];
          } else {

            if (this.selecteds.length > 0) {
              // remove
              for (let i = 0; i < this.selecteds.length; i++) {
                if (this.selecteds[i].Id === row.Id) {
                  this.selecteds.splice(i, 1);
                  break;
                }
              }
            }
            else {
              this.selecteds = this.selecteds;
            }
            this.selecteds = [...this.selecteds];
            event.target.checked = false;
          }

        })
      }

      if (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) > dateTime) {
        swal.fire({
          title: 'مشکل بیمه',
          text: "بیمه این عضو منقضی شده است. آیا مجاز به شرکت در برنامه می باشند؟",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0CC27E',
          cancelButtonColor: '#FF586B',
          confirmButtonText: 'بله',
          cancelButtonText: 'خیر',
          confirmButtonClass: 'btn btn-success btn-raised mr-5',
          cancelButtonClass: 'btn btn-danger btn-raised',
          buttonsStyling: false
        }).then((res) => {
          if (res.value) {
            this.selecteds.push(row);

            this.selecteds = [...this.selecteds];
          } else {
            if (this.selecteds.length > 0) {
              // remove
              for (let i = 0; i < this.selecteds.length; i++) {
                if (this.selecteds[i].Id === row.Id) {
                  this.selecteds.splice(i, 1);
                  break;
                }
              }
            }
            else {
              this.selecteds = this.selecteds;
            }
            this.selecteds = [...this.selecteds];
            event.target.checked = false;
          }

        })
      }

      if (Date.parse(row.EndDateOfInsurance) > dateTime && Date.parse(row.EndDateOfTuition) < dateTime) {
        swal.fire({
          title: 'مشکل شهریه',
          text: "شهریه این عضو پرداخت نگردیده است. آیا مجاز به شرکت در برنامه می باشند؟",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0CC27E',
          cancelButtonColor: '#FF586B',
          confirmButtonText: 'بله',
          cancelButtonText: 'خیر',
          confirmButtonClass: 'btn btn-success btn-raised mr-5',
          cancelButtonClass: 'btn btn-danger btn-raised',
          buttonsStyling: false
        }).then((res) => {
          if (res.value) {
            this.selecteds.push(row);

            this.selecteds = [...this.selecteds];
          } else {
            if (this.selecteds.length > 0) {
              // remove
              for (let i = 0; i < this.selecteds.length; i++) {
                if (this.selecteds[i].Id === row.Id) {
                  this.selecteds.splice(i, 1);
                  break;
                }
              }
            }
            else {
              this.selecteds = this.selecteds;
            }
            this.selecteds = [...this.selecteds];
            event.target.checked = false;

          }

        })
      }

      if (Date.parse(row.EndDateOfInsurance) > dateTime && Date.parse(row.EndDateOfTuition) > dateTime) {
        this.selecteds.push(row);

        this.selecteds = [...this.selecteds];
      }

    } else {
      // remove
      for (let i = 0; i < this.selecteds.length; i++) {
        if (this.selecteds[i].Id === row.Id) {
          this.selecteds.splice(i, 1);
          this.selecteds = [...this.selecteds];
          break;
        }
      }
    }
  }

  public getChecked(row: any): boolean {
    // autofill checked
    const item = this.selecteds.filter((e) => e.Id === row.Id);
    return item.length > 0 ? true : false;
  }

  removeMember(id) {
    this.selecteds = this.selecteds.filter(i => i.Id != id);

    this.selecteds = [...this.selecteds];
  }

  getMembers() {
    this.memberService.getMembers().subscribe(members => {
      this.rows = members;
      this.temp = [...members];
      setTimeout(() => {
        this.loadingIndicator = false;
      }, 1000);
    });
  }

  createForm() {

    this.planAddForm = this.formBuilder.group({
      Name: new FormControl('', [Validators.required]),
      PlanNo: new FormControl('ش ج-10-', [Validators.required]),
      PlanTypeIds: new FormControl('', [Validators.required]),
      StartDate: new FormControl(null, [Validators.required]),
      EndDate: new FormControl(null, [Validators.required]),
      Duration: new FormControl(null, [Validators.required]),
      NumberOfAttendance: new FormControl(null, [Validators.required]),
      Country: new FormControl(),
      State: new FormControl(),
      City: new FormControl(),
      Area: new FormControl(),
      PlanRate: new FormControl(null, [Validators.required]),
      IssueDate: new FormControl(null, [Validators.required]),
      MembersPlans: this.formBuilder.array([]),
      NonMembers: this.formBuilder.array([])
    });

    this.planAddNonMemberForm = this.formBuilder.group({
      FirstName: new FormControl(),
      LastName: new FormControl(),
      FatherName: new FormControl(),
      NationalCode: new FormControl(),
      CarName: new FormControl(),
      CarPlaque: new FormControl(),
      Mobile: new FormControl(),
      ResponsibilityInPlanIds: new FormControl(),
      Description: new FormControl(),
    });

  }

  get Name() { return this.planAddForm.get('Name'); }

  getPlanTypes() {
    this.planTypesService.getPlanTypes().subscribe(
      x => {
        this.planTypes = x;
      }
    );
  }

  arrayToString(arrayObj: any) {
    var str = arrayObj.join();
    return str;
  }

  jalaliToMiladi(date: any) {
    return moment(date, 'jYYYY/jMM/jDD HH:mm').format('YYYY-MM-DD HH:mm:ss');
  }

  convertToJSON(array: any) {
    return JSON.parse(array);
  }

  addPlan() {

    if (this.planAddForm.invalid) {
      this.planAddForm.get('Name').markAsTouched();
      this.planAddForm.get('PlanNo').markAsTouched();
      this.planAddForm.get('PlanTypeIds').markAsTouched();
      this.planAddForm.get('StartDate').markAsTouched();
      this.planAddForm.get('EndDate').markAsTouched();
      this.planAddForm.get('Duration').markAsTouched();
      this.planAddForm.get('NumberOfAttendance').markAsTouched();
      this.planAddForm.get('PlanRate').markAsTouched();
      this.planAddForm.get('IssueDate').markAsTouched();

      return;
    }

    this.objForm = this.planAddForm.getRawValue();

    if (this.planAddForm.get("PlanTypeIds").value) {
      this.objForm.PlanTypeIds = this.arrayToString(this.planAddForm.get("PlanTypeIds").value);
    }

    if (this.planAddForm.get("StartDate").value) {
      this.objForm.StartDate = this.jalaliToMiladi(this.planAddForm.get("StartDate").value);
    }

    if (this.planAddForm.get("EndDate").value) {
      this.objForm.EndDate = this.jalaliToMiladi(this.planAddForm.get("EndDate").value);
    }

    if (this.planAddForm.get("Duration").value) {
      this.objForm.Duration = +(this.planAddForm.get("Duration").value);
    }

    if (this.planAddForm.get("NumberOfAttendance").value) {
      this.objForm.NumberOfAttendance = +(this.planAddForm.get("NumberOfAttendance").value);
    }

    if (this.planAddForm.get("PlanRate").value) {
      this.objForm.PlanRate = +(this.planAddForm.get("PlanRate").value);
    }

    if (this.planAddForm.get("Country").value) {
      this.objForm.Country = (this.planAddForm.get("Country").value);
    }

    if (this.planAddForm.get("State").value) {
      this.objForm.State = (this.planAddForm.get("State").value);
    }

    if (this.planAddForm.get("IssueDate").value) {
      this.objForm.IssueDate = this.jalaliToMiladi(this.planAddForm.get("IssueDate").value);
    }

    if (this.selecteds.length > 0) {
      for (var i = 0; i < this.selecteds.length; i++) {
        var membersPlan = new MembersPlan();
        membersPlan.planId = 0;
        membersPlan.memberId = this.selecteds[i].Id;
        membersPlan.responsibilityInPlanIds = "";
        membersPlan.equipmentQuality = 0;
        membersPlan.environmentProtection = 0;
        membersPlan.physicalState = 0;
        membersPlan.stamina = 0;
        membersPlan.skillAndTechnique = 0;
        membersPlan.teamWork = 0;
        membersPlan.publicEquipmentProtection = 0;
        membersPlan.averageScore = 0;
        membersPlan.precentOfScore = 0;
        this.objForm.MembersPlans.push(membersPlan);
      }
    }

    if (this.selectedNonMembers.length > 0) {
      for (var i = 0; i < this.selectedNonMembers.length; i++) {
        var non = new NonMember();
        non.planId = 0;
        non.firstName = this.selectedNonMembers[i].firstName;
        non.lastName = this.selectedNonMembers[i].lastName;
        non.fatherName = this.selectedNonMembers[i].fatherName;
        non.nationalCode = this.selectedNonMembers[i].nationalCode;
        non.carName = this.selectedNonMembers[i].carName;
        non.carPlaque = this.selectedNonMembers[i].carPlaque;
        non.mobile = this.selectedNonMembers[i].mobile;
        non.description = this.selectedNonMembers[i].description;
        if (this.selectedNonMembers[i].responsibilitiesInPlanIds) {
          non.responsibilitiesInPlanIds = this.selectedNonMembers[i].responsibilitiesInPlanIds;
        }
        this.objForm.NonMembers.push(non);
      }
    }

    this.planService.savePlans(this.objForm).subscribe(
      result => {

        if (result['statusCode'] == 2022) {
          this.toastr.error('برنامه با شماره فوق قبلا ثبت شده است.', 'خطا', {
          });
          return;
        }

        this.messages = result.message;
        this.planAddForm.reset();
        this.planAddNonMemberForm.reset();
        this.selecteds = [];
        this.selectedNonMembers = [];
        this.enable = false;
        this.toastr.success(this.objForm.Name + ' با موفقیت ذخیره شد.', 'موفقیت');
      },
      error => {
        //this.errors = error;
        this.toastr.error('ذخیره ' + this.objForm.Name + ' با شکست مواجه شد.', 'خطا');
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      }
    );
  }

  updateFilter(event) {
    const val = event.target.value;
    // filter our data
    const temp = this.temp.filter(function (d) {
      const name = d.FirstName + ' ' + d.LastName;
      return name.indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    //this.table.offset = 0;
  }

  checkConditions(row) {
    let dateTime = Date.now();

    if (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) < dateTime) {
      return {
        'insurance-and-tuition': (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) < dateTime)
      }
    }

    if (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) > dateTime) {
      return {
        'insurance': (Date.parse(row.EndDateOfInsurance) < dateTime && Date.parse(row.EndDateOfTuition) > dateTime)
      }
    }

    if (true) {
      return {
        'tuition': (Date.parse(row.EndDateOfInsurance) > dateTime && Date.parse(row.EndDateOfTuition) < dateTime)
      }
    }
  }

}

class MembersPlan {
  constructor(
    public id?: number,
    public planId?: number,
    public memberId?: number,
    public responsibilityInPlanIds?: string,
    public equipmentQuality?: number,
    public environmentProtection?: number,
    public physicalState?: number,
    public stamina?: number,
    public skillAndTechnique?: number,
    public teamWork?: number,
    public publicEquipmentProtection?: number,
    public averageScore?: number,
    public precentOfScore?: number
  ) { }
}

class NonMember {
  constructor(
    public id?: number,
    public planId?: number,
    public firstName?: string,
    public lastName?: string,
    public fatherName?: string,
    public nationalCode?: string,
    public mobile?: string,
    public carName?: string,
    public carPlaque?: string,
    public responsibilitiesInPlanIds?: string,
    public description?: string,
  ) { }
}
