import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from "rxjs/operators";
import { Member } from '../member/member.model';

@Injectable()
export class MemberService {

  private currentUserSubject: BehaviorSubject<Member>;
  currentUser: Observable<Member>;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

  constructor(private httpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<Member>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  member: Member;

  public get currentUserObject(): Member {
    return this.currentUserSubject.value;
  }

  getMembers(): Observable<Member[]> {
    return this.httpClient.get<Member[]>('/api/members')
      .pipe(map(res => res));
  }

  getMemberById(id: number) {
    return this.httpClient.get('/api/members/' + id)
      .pipe(map(res => res));
  }

  saveMembers(member: Member) {
    return this.httpClient.post<Member>('/api/members', member).map(
      res => res
    );
  }

  editMember(id: number, member: Member) {
    return this.httpClient.put('/api/members/' + id, member)
      .pipe(map(res => res));
  }

  generateCard(id: number) {
    return this.httpClient.get('/api/members/GenerateCard/' + id, { responseType: 'blob' })
      .pipe(map(res => res));
  }

  deleteMember(id: number) {
    return this.httpClient.delete('/api/members/' + id)
      .pipe(map(res => res));
  }

  isAuthorized() {
    return !!this.currentUserObject;
  }

  hasRole() {
    //return this.isAuthorized() && this.user.role === role;
    let currentUser = this.currentUserObject;
    if (currentUser && currentUser.ResponsibilitiesInClubIds) {
      var responsibilities = currentUser.ResponsibilitiesInClubIds.split(',');
      for (var i = 0; i < responsibilities.length; i++) {
        if (currentUser && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 21].indexOf(+(responsibilities[i])) > -1) {
          return true;
        }
      }
    }
  }

  whatsRole() {
    let currentUser = this.currentUserObject;
    if (currentUser && currentUser.ResponsibilitiesInClubIds) {
      var responsibilities = currentUser.ResponsibilitiesInClubIds.split(',');
      for (var i = 0; i < responsibilities.length; i++) {
        if (currentUser && [1, 22].indexOf(+(responsibilities[i])) > -1) {
          return 'Admin';
        }
      }
      for (var i = 0; i < responsibilities.length; i++) {
        if (currentUser && [23].indexOf(+(responsibilities[i])) > -1) {
          return 'Supervisor';
        }
      }
      for (var i = 0; i < responsibilities.length; i++) {
        if (currentUser && [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].indexOf(+(responsibilities[i])) > -1) {
          return 'User';
        }
      }
    }
  }

  login(member: Member) {
    return this.httpClient.post<Member>('/api/members/login', member)
      .pipe(map(user => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user
      }));
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  //registerUser(member: Member) {
  //  return this.httpClient.post('/api/members', member).map(
  //    res => res
  //  );
  //}
}
