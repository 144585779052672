import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PlanType } from '../parameters/planType/planType.model';

@Injectable()
export class PlanTypesService {

  constructor(private httpClient: HttpClient) { }

  planTypes: any;

  getPlanTypes(): Observable<any> {
    return this.httpClient.get<any>('/api/planTypes')
      .pipe(map(res => res));
  }

  savePlanTypes(planTypes: any){
    return this.httpClient.post<any>('/api/planTypes', planTypes).map(
          res => res
      );
  }

  editPlanType(id: number, planType: PlanType) {
    return this.httpClient.put('/api/planTypes/' + id, planType)
      .pipe(map(res => res));
  }

  deletePlanType(id: number) {
    return this.httpClient.delete('/api/planTypes/' + id)
      .pipe(map(res => res));
  }
}
