import { PlanService } from '../../services/plan.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { MemberService } from '../../services/member.service';
import { Role } from '../../member/member.model';

@Component({
  selector: 'app-plan-list',
  templateUrl: './list.component.html'
})
export class PlanComponent {
  name: string = "Hello Plan";
  planForm: any;
  rows: any;
  p: number = 1;
  loadingIndicator = true;
  reorderable = true;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  temp = [];
  memberRole: string;

  constructor(
    private fb: FormBuilder,
    private planService: PlanService,
    private memberService: MemberService,
    public toastr: ToastrService) {
  }

  ngOnInit() {
    this.memberRole = this.memberService.whatsRole();
    if (this.memberRole != Role.Admin) {
      this.memberService.logout();
    }
    this.getPlans();
  }

  getPlans() {
    this.planService.getPlans().subscribe(plans => {
      this.rows = plans;
      this.temp = [...plans];
      setTimeout(() => {
        this.loadingIndicator = false;
      }, 1000);
    });
  }

  updateFilter(event) {
    const val = event.target.value;
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.Name.indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  deletePlan(id: number, name: string) {
    swal.fire({
      title: 'حذف آیتم',
      text: "آیا از حدف این آیتم مطمین هستید؟",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0CC27E',
      cancelButtonColor: '#FF586B',
      confirmButtonText: 'بله',
      cancelButtonText: 'خیر',
      confirmButtonClass: 'btn btn-success btn-raised mr-5',
      cancelButtonClass: 'btn btn-danger btn-raised',
      buttonsStyling: false
    }).then((res) => {
      if (res.value) {
        this.planService.deletePlan(id).subscribe(
          result => {
            console.log(result);
            this.toastr.success(name + ' با موفقیت حذف شد.', 'موفقیت');
            this.getPlans();
          },
          error => {
            //this.errors = error;
            this.toastr.error('حذف ' + name + ' با شکست مواجه شد.', 'خطا');
          },
          () => {
            // 'onCompleted' callback.
            // No errors, route to new page here
          }
        );
      } else {
        // handle cancel
      }

    })
  }

}
