import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Plan } from '../plan/plan.model';

@Injectable()
export class PlanService {

  constructor(private httpClient: HttpClient) { }

  plan: any;

  getPlans(): Observable<Plan[]> {
    return this.httpClient.get<Plan[]>('/api/plans')
      .pipe(map(res => res));
  }

  getFullPlans(memberId: number): Observable<Plan[]> {
    return this.httpClient.get<Plan[]>('/api/plans/GetFullPlans/' + memberId)
      .pipe(map(res => res));
  }

  getPlanById(id: number) {
    return this.httpClient.get('/api/plans/' + id)
      .pipe(map(res => res));
  }

  savePlans(plan: any) {
    return this.httpClient.post<any>('/api/plans', plan).map(
      res => res
    );
  }

  editPlan(id: number, plan: any) {
    return this.httpClient.put('/api/plans/' + id, plan)
      .pipe(map(res => res));
  }

  deletePlan(id: number) {
    return this.httpClient.delete('/api/plans/' + id)
      .pipe(map(res => res));
  }

  downloadLicense(id: number) {
    return this.httpClient.get('/api/plans/DownloadLicense/' + id, { responseType: 'blob' })
      .pipe(map(res => res));
  }

  downloadInsurance(id: number) {
    return this.httpClient.get('/api/plans/DownloadInsurance/' + id, { responseType: 'blob' })
      .pipe(map(res => res));
  }

  emailInsurance(id: number) {
    return this.httpClient.post<any>('/api/plans/emailInsurance/' + id, id).map(
      res => res
    );
  }
}
