import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ResponsibilitiesInClub } from '../parameters/responsibilitiesInClub/responsibilitiesInClub.model';

@Injectable()
export class ResponsibilitiesInClubService {

  constructor(private httpClient: HttpClient) { }

  responsibilitiesInClub: any;

  getResponsibilitiesInClub(): Observable<any> {
    return this.httpClient.get<any>('/api/responsibilitiesInClub')
      .pipe(map(res => res));
  }

  saveResponsibilitiesInClub(responsibilitiesInClub: any){
    return this.httpClient.post<any>('/api/responsibilitiesInClub', responsibilitiesInClub).map(
          res => res
      );
  }

  editResponsibilitiesInClub(id: number, responsibilitiesInClub: ResponsibilitiesInClub) {
    return this.httpClient.put('/api/responsibilitiesInClub/' + id, responsibilitiesInClub)
      .pipe(map(res => res));
  }

  deleteResponsibilitiesInClub(id: number) {
    return this.httpClient.delete('/api/responsibilitiesInClub/' + id)
      .pipe(map(res => res));
  }
}
