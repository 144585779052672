import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MemberService } from './services/member.service';
import { Member } from './member/member.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  currentUser: Member;

  constructor(private router: Router,
    private memberService: MemberService) {
    this.memberService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  logOut(msg: string) {
    this.memberService.logout();
    this.router.navigate(["/register/login"]);
  }
}
