import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MemberRoutingModule } from "./member-routing.module";
import { UiSwitchModule } from 'ngx-ui-switch';

import { DpDatePickerModule } from 'ng2-jalali-date-picker';
import { JalaliPipe } from '../shared/pipes/jalali.pipe';
import { PerNumberPipe } from '../shared/pipes/per-number.pipe';

import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { MemberComponent } from "./list/list.component";
import { MemberAddComponent } from "./add/add.component";
import { MemberEditComponent } from "./edit/edit.component";
import { FileUploadModule } from "ng2-file-upload";   //Should import HERE

import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  imports: [
    CommonModule,
    MemberRoutingModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    PerfectScrollbarModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule,
    DpDatePickerModule,
    NgSelectModule,
    FileUploadModule
  ],
  declarations: [
    MemberComponent,
    MemberAddComponent,
    MemberEditComponent,
    PerNumberPipe,
    JalaliPipe    
  ],
  exports: [
    PerNumberPipe,
    JalaliPipe
  ]
})
export class MemberModule { }
