
export class Member {
  public gender: boolean = false;
  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  public fatherName: string = '';
  public nationalCode: string = '';
  public idNumber: string = '';
  public birthDate: any;
  public bloodGroup: string = '';
  public job: string = '';
  public country: string = '';
  public state: string = '';
  public city: string = '';
  public address: string = '';
  public mobile: string = '';
  public membershipDate: any;
  public endDateOfInsurance: any;
  public insuranceNo: string = '';
  public educationLevel: string = '';
  public ResponsibilitiesInClubIds: string = '';
  public certificationIds: string = '';
  public situationIds: string = '';
  public Token: string = '';
  public skillIds: string = '';
  public endDateOfTuition: any;
}

export enum Role {
  User = 'User',
  Admin = 'Admin',
  Supervisor = 'Supervisor'
}

