import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponsibilitiesInPlan } from '../parameters/responsibilitiesInPlan/responsibilitiesInPlan.model';

@Injectable()
export class ResponsibilitiesInPlanService {

  constructor(private httpClient: HttpClient) { }

  responsibilitiesInPlan: any;

  getResponsibilitiesInPlan(): Observable<any> {
    return this.httpClient.get<any>('/api/responsibilitiesInPlan')
      .pipe(map(res => res));
  }

  saveResponsibilitiesInPlan(responsibilitiesInPlan: any) {
    return this.httpClient.post<any>('/api/responsibilitiesInPlan', responsibilitiesInPlan).map(
      res => res
    );
  }

  editResponsibilitiesInPlan(id: number, responsibilitiesInPlan: ResponsibilitiesInPlan) {
    return this.httpClient.put('/api/responsibilitiesInPlan/' + id, responsibilitiesInPlan)
      .pipe(map(res => res));
  }

  deleteResponsibilitiesInPlan(id: number) {
    return this.httpClient.delete('/api/responsibilitiesInPlan/' + id)
      .pipe(map(res => res));
  }
}
