import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { MemberService } from './member.service';
import { Member } from '../member/member.model';

@Injectable()
export class DashboardService {

  private currentUserSubject: BehaviorSubject<Member>;
  currentUser: Observable<Member>;
  user: any;
  token: any;
  dashboard: any;
  httpOptions = {
    headers: new HttpHeaders(
      { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': this.token })
  };

  constructor(private httpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<Member>(JSON.parse(JSON.stringify(localStorage.getItem('currentUser'))));
    this.currentUser = this.currentUserSubject.asObservable();
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.token = this.user.Token;
  }

  getDashboardInfo(): Observable<any> {
    return this.httpClient.get<any>('/api/dashboard')
      .pipe(map(res => res));
  }

  getFullPlans() {
    return this.httpClient.get('/api/dashboard/GetFullPlans', this.httpOptions)
      .pipe(map(res => res));
  }
}
