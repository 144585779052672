import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PlanRoutingModule } from "./plan-routing.module";
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DpDatePickerModule } from 'ng2-jalali-date-picker';
import { JalaliPipe } from '../shared/pipes/jalali.pipe';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlanComponent } from "./list/list.component";
import { PlanAddComponent } from "./add/add.component";
import { PlanEditComponent } from "./edit/edit.component";
import { PlanAssessmentComponent } from "./assessment/assessment.component";

import { MemberModule } from "../member/member.module";

import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    PlanRoutingModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    PerfectScrollbarModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule,
    DpDatePickerModule,
    NgSelectModule,
    MemberModule,
    NgbModule
  ],
  declarations: [
    PlanComponent,
    PlanAddComponent,
    PlanEditComponent,
    PlanAssessmentComponent
  ]
})
export class PlanModule { }
