import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Certification } from '../parameters/certification/certification.model';

@Injectable()
export class CertificationService {

  constructor(private httpClient: HttpClient) { }

  certification: any;

  getCertifications(): Observable<any> {
    return this.httpClient.get<any>('/api/certifications')
      .pipe(map(res => res));
  }

  saveCertifications(certification: Certification) {
    return this.httpClient.post<Certification>('/api/certifications', certification).map(
      res => { res; }
    );
  }

  editCertification(id: number, certification: Certification) {
    debugger;
    return this.httpClient.put('/api/certifications/' + id, certification)
      .pipe(map(res => res));
  }

  deleteCertification(id: number) {
    return this.httpClient.delete('/api/certifications/' + id)
      .pipe(map(res => res));
  }

}
