import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MemberService } from '../../services/member.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private memberService: MemberService,
    private route: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let currentUser = this.memberService.currentUserObject;

    if (currentUser && currentUser.ResponsibilitiesInClubIds) {
      var responsibilities = currentUser.ResponsibilitiesInClubIds.split(',');
      for (var i = 0; i < responsibilities.length; i++) {
        if (currentUser && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 21, 23].indexOf(+(responsibilities[i])) > -1) {
          return true;
        }
      }
    }

    this.memberService.logout();
    this.route.navigate(['/register/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}
