import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'forms',
    loadChildren: () => import('../../forms/forms.module').then(m => m.FormModule)
  },
  {
    path: 'member',
    loadChildren: () => import('../../member/member.module').then(m => m.MemberModule)
  },
  {
    path: 'plan',
    loadChildren: () => import('../../plan/plan.module').then(m => m.PlanModule)
  },
  {
    path: 'parameters',
    loadChildren: () => import('../../parameters/parameters.module').then(m => m.ParametersModule)
  }
];
