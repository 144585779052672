import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CertificationComponent } from "./certification/certification.component";
import { PlanTypeComponent } from "./planType/planType.component";
import { ResponsibilitiesInClubComponent } from "./responsibilitiesInClub/responsibilitiesInClub.component";
import { ResponsibilitiesInPlanComponent } from "./responsibilitiesInPlan/responsibilitiesInPlan.component";
import { SituationComponent } from './situation/situation.component';
import { SkillComponent } from './skill/skill.component';

const routes: Routes = [
  {
    path: '',
    children: [

      {
        path: 'certification',
        component: CertificationComponent,
        data: {
          title: ''
        }
      },
      {
        path: 'situation',
        component: SituationComponent,
        data: {
          title: ''
        }
      },
      {
        path: 'planType',
        component: PlanTypeComponent,
        data: {
          title: ''
        }
      },
      {
        path: 'skill',
        component: SkillComponent,
        data: {
          title: ''
        }
      },
      {
        path: 'responsibilitiesInClub',
        component: ResponsibilitiesInClubComponent,
        data: {
          title: ''
        }
      },
      {
        path: 'responsibilitiesInPlan',
        component: ResponsibilitiesInPlanComponent,
        data: {
          title: ''
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ParametersRoutingModule { }
