import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RegisterRoutingModule } from "./register-routing.module";
import { UiSwitchModule } from 'ngx-ui-switch';

import { DpDatePickerModule } from 'ng2-jalali-date-picker';

import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { LoginPageComponent } from "./login/login-page.component";

import { MemberModule } from "../member/member.module";

import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    RegisterRoutingModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    PerfectScrollbarModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule,
    DpDatePickerModule,
    MemberModule,
    NgSelectModule
  ],
  declarations: [
    LoginPageComponent
  ],
  exports: [
  ]
})
export class RegisterModule { }
