import { Component, ViewEncapsulation } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Skill } from './skill.model';
import { SkillService } from '../../services/skill.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';


@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SkillComponent {
  source: LocalDataSource;
  filterSource: LocalDataSource;
  skills: Skill[] = [];

  constructor(private skillService: SkillService,
    public toastr: ToastrService) {
    this.getSkills(); // create the source
  }

  alertsettings = {
    actions: {
      columnTitle: ''
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="ft-x danger font-medium-1 mr-2"></i>'
    },
    add: {
      confirmCreate: true,
      addButtonContent: 'جدید',
      cancelButtonContent: 'لغو ',
      createButtonContent: 'ایجاد ',
    },
    edit: {
      confirmSave: true,
      editButtonContent: '<i class="ft-edit-2 info font-medium-1 mr-2"></i>',
      cancelButtonContent: 'لغو ',
      saveButtonContent: 'ذخیره ',
    },
    columns: {
      Id: {
        title: 'شناسه',
        filter: false,
        editable: false,
        addable: false,
        type: 'number',
        defaultValue: 0,
        width: '1px',
      },
      Skill: {
        title: 'مهارت ها',
      }
    },
    attr: {
      class: "table table-responsive"
    },
  };

  getSkills() {
    this.skillService.getSkills().subscribe(
      x => this.skills = x
    );
  }

  // And the listener code which asks the DataSource to filter the data:
  onSearch(query: string = '') {
    this.source.setFilter([
      // fields we want to inclue in the search
      {
        field: 'Id',
        search: query,
      },
      {
        field: 'Skill',
        search: query,
      }
    ], false);
    // second parameter specifying whether to perform 'AND' or 'OR' search 
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }

  //  For confirm action On Delete
  onDeleteConfirm(event) {
    swal.fire({
      title: 'حذف آیتم',
      text: "آیا از حدف این آیتم مطمین هستید؟",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0CC27E',
      cancelButtonColor: '#FF586B',
      confirmButtonText: 'بله',
      cancelButtonText: 'خیر',
      confirmButtonClass: 'btn btn-success btn-raised mr-5',
      cancelButtonClass: 'btn btn-danger btn-raised',
      buttonsStyling: false
    }).then((res) => {
      if (res.value) {
        event.data.Id = +(event.data.Id);
        this.skillService.deleteSkill(event.data.Id).subscribe(
          result => {
            if (result['statusCode'] == 2020) {
              this.toastr.error('این گزینه برای همنوردان ثبت شده و قابل حذف نمی باشد.', 'خطا', {
              });
              return;
            }
            event.confirm.resolve();
            this.toastr.success(event.data.Skill + ' با موفقیت حذف شد.', 'موفقیت');
            this.getSkills();
          },
          error => {
            event.confirm.reject();
            //this.errors = error;
            this.toastr.error('حذف ' + event.data.Skill + ' با شکست مواجه شد.', 'خطا');
          },
          () => {
            // 'onCompleted' callback.
            // No errors, route to new page here
          }
        );
      } else {
        // handle cancel
      }
    })
  }

  //  For confirm action On Create
  onCreateConfirm(event) {
    event.newData.Id = +(event.newData.Id);
    event.confirm.resolve(event.newData);
    this.skillService.saveSkills(event.newData).subscribe(
      result => {
        event.confirm.resolve();
        this.toastr.success(event.newData.Skill + ' با موفقیت ذخیره شد.', 'موفقیت');
        this.getSkills();
      },
      error => {
        event.confirm.reject();
        //this.errors = error;
        this.toastr.error('ذخیره ' + event.newData.Skill + ' با شکست مواجه شد.', 'خطا');
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      }
    );
  }

  //  For confirm action On Save
  onSaveConfirm(event) {
    event.newData.Id = +(event.newData.Id);
    event.confirm.resolve(event.newData);
    this.skillService.editSkill(event.newData.Id, event.newData).subscribe(
      result => {
        event.confirm.resolve();
        this.toastr.success(event.newData.Skill + ' با موفقیت ویرایش شد.', 'موفقیت');
        this.getSkills();
      },
      error => {
        event.confirm.reject();
        //this.errors = error;
        this.toastr.error('ویرایش ' + event.data.Skill + ' با شکست مواجه شد.', 'خطا');
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      }
    );
  }
}

