import { RouteInfo } from './sidebar.metadata';
import { Role } from '../../member/member.model';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard/dashboard', title: 'خانه', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: [Role.Admin, Role.Supervisor, Role.User]
  },
  {
    path: '', title: 'امور همنوردان', icon: 'ft-user', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
      { path: '/member/list', title: 'فهرست همنوردان', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: []  },
      { path: '/member/add', title: 'ثبت همنورد', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: []  },
    ], roles: [Role.Admin, Role.User] 
  },
  {
    path: '', title: 'امور برنامه ها', icon: 'ft-calendar', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
      { path: '/plan/list', title: 'فهرست برنامه ها', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: []  },
      { path: '/plan/add', title: 'ثبت برنامه', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: []  },
      //{ path: '/plan/assessment', title: 'ارزیابی برنامه', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: []  },
    ], roles: [Role.Admin, Role.Supervisor] 
  },
  {
    path: '', title: 'مقادیر اولیه', icon: 'ft-settings', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
      { path: '/parameters/certification', title: 'گواهی ها', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: []  },
      { path: '/parameters/situation', title: 'وضعیت', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: []  },
      { path: '/parameters/planType', title: 'نوع برنامه', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: []  },
      { path: '/parameters/skill', title: 'مهارت ها', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: []  },
      { path: '/parameters/responsibilitiesInClub', title: 'مسئولیت ها در باشگاه', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: []  },
      { path: '/parameters/responsibilitiesInPlan', title: 'مسئولیت ها در برنامه', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: []  },
    ], roles: [Role.Admin, Role.User]
  }];
