import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { MemberService } from '../../services/member.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent {

  ///@ViewChild('f', { static: false }) loginForm: NgForm;
  loginForm: FormGroup;
  objForm;
  messages;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private memberService: MemberService) {

    if (this.memberService.currentUserObject) {
      this.router.navigate(["/dashboard/dashboard"]);
    }
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      NationalCode: new FormControl('', [Validators.required]),
      Password: new FormControl('', Validators.required)
    });
  }

  // On submit button click
  onSubmit() {

    this.objForm = this.loginForm.getRawValue();

    this.memberService.login(this.objForm).subscribe(
      result => {
        //this.router.navigate(['/dashboard/dashboard'], { queryParams: { returnUrl: this.state.url } });
        this.router.navigate(['/dashboard/dashboard']);
      },
      error => {
        //this.errors = error;
        
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      }
    );
  }
  //// On Forgot password link click
  //onForgotPassword() {
  //    this.router.navigate(['forgotpassword'], { relativeTo: this.route.parent });
  //}
  //// On registration link click
  //onRegister() {
  //    this.router.navigate(['register'], { relativeTo: this.route.parent });
  //}
}
