import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ParametersRoutingModule } from "./parameters-routing.module";

import { CertificationComponent } from "./certification/certification.component";
import { SituationComponent } from "./situation/situation.component";
import { PlanTypeComponent } from "./planType/planType.component";
import { SkillComponent } from "./skill/skill.component";
import { ResponsibilitiesInClubComponent } from "./responsibilitiesInClub/responsibilitiesInClub.component";
import { ResponsibilitiesInPlanComponent } from "./responsibilitiesInPlan/responsibilitiesInPlan.component";

@NgModule({
  imports: [
    CommonModule,
    ParametersRoutingModule,
    Ng2SmartTableModule
  ],
  declarations: [
    CertificationComponent,
    PlanTypeComponent,
    ResponsibilitiesInClubComponent,
    ResponsibilitiesInPlanComponent,
    SituationComponent,
    SkillComponent
  ]
})
export class ParametersModule { }
