import { MemberService } from '../../services/member.service';
import { ResponsibilitiesInClubService } from '../../services/responsibilitiesInClub.service';
import { CertificationService } from '../../services/certification.service';
import { SituationService } from '../../services/situation.service';
import { SkillService } from '../../services/skill.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'jalali-moment';

@Component({
  selector: 'app-member-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})

export class MemberAddComponent implements OnInit{
  // create object of form group
  memberAddForm: FormGroup;
  genderText = 'بانو';
  enable = true;
  responsibilitiesInClub;
  certifications;
  situations;
  skills;
  objForm;
  messages;
  config = {};

  constructor(private formBuilder: FormBuilder,
    private memberService: MemberService,
    private responsibilitiesInClubService: ResponsibilitiesInClubService,
    private certificationService: CertificationService,
    private situationService: SituationService,
    private skillService: SkillService,
    public toastr: ToastrService) {
  }

  ngOnInit() {

    this.getResponsibilitiesInClub();
    this.getCertifications();
    this.getSituations();
    this.getSkills();

    this.createForm();

  }

  onChange() {
    if (this.genderText === 'بانو') {
      this.genderText = 'آقا';
    }
    else {
      this.genderText = 'بانو';
    }
  }

  createForm() {

    this.memberAddForm = this.formBuilder.group({
      'Gender': new FormControl(false),
      'FirstName': new FormControl('', [Validators.required]),
      'LastName': new FormControl('', [Validators.required]),
      'Email': new FormControl(null),
      'FatherName': new FormControl(null),
      'NationalCode': new FormControl(null, [Validators.required, Validators.maxLength(10)]),
      'IdNumber': new FormControl(),
      'BirthDate': new FormControl(null),
      'BloodGroup': new FormControl(),
      'Job': new FormControl(),
      'Country': new FormControl(),
      'State': new FormControl(),
      'City': new FormControl(),
      'Address': new FormControl(),
      'Mobile': new FormControl(),
      'MembershipDate': new FormControl(null),
      'EndDateOfInsurance': new FormControl(null),
      'InsuranceNo': new FormControl(),
      'EducationLevel': new FormControl(),
      'ResponsibilitiesInClubIds': new FormControl(''),
      'CertificationIds': new FormControl(''),
      'SituationIds': new FormControl(''),
      'SkillIds': new FormControl(''),
      'EndDateOfTuition': new FormControl(null),
      'Password': new FormControl()
    });
  }

  get FirstName() { return this.memberAddForm.get('FirstName'); }

  getResponsibilitiesInClub() {
    this.responsibilitiesInClubService.getResponsibilitiesInClub().subscribe(
      x => this.responsibilitiesInClub = x
    );
  }

  getCertifications() {
    this.certificationService.getCertifications().subscribe(
      x => this.certifications = x
    );
  }

  getSituations() {
    this.situationService.getSituations().subscribe(
      x => this.situations = x
    );
  }

  getSkills() {
    this.skillService.getSkills().subscribe(
      x => this.skills = x
    );
  }

  arrayToString(arrayObj: any) {
    var str = arrayObj.join();
    return str;
  }

  jalaliToMiladi(date: any) {
    return moment(date, 'jYYYY/jMM/jDD HH:mm').format('YYYY-MM-DD HH:mm:ss');
  }

  convertToJSON(arrayObj: any) {
    return JSON.parse(arrayObj);
  }

  addMember() {

    if (this.memberAddForm.invalid) {
      this.memberAddForm.get('FirstName').markAsTouched();
      this.memberAddForm.get('LastName').markAsTouched();
      this.memberAddForm.get('NationalCode').markAsTouched();
      return;
    }

    this.objForm = this.memberAddForm.getRawValue();
    console.log(this.objForm);

    if (this.memberAddForm.get("ResponsibilitiesInClubIds").value) {
      this.objForm.ResponsibilitiesInClubIds = this.arrayToString(this.memberAddForm.get("ResponsibilitiesInClubIds").value);
    }
    
    if (this.memberAddForm.get("CertificationIds").value) {
      this.objForm.CertificationIds = this.arrayToString(this.memberAddForm.get("CertificationIds").value);
    }

    if (this.memberAddForm.get("SituationIds").value) {
      this.objForm.SituationIds = this.arrayToString(this.memberAddForm.get("SituationIds").value);
    }

    if (this.memberAddForm.get("SkillIds").value) {
      this.objForm.SkillIds = this.arrayToString(this.memberAddForm.get("SkillIds").value);
    }

    if (this.memberAddForm.get("BirthDate").value) {
      this.objForm.BirthDate = this.jalaliToMiladi(this.memberAddForm.get("BirthDate").value);
    }

    if (this.memberAddForm.get("MembershipDate").value) {
      this.objForm.MembershipDate = this.jalaliToMiladi(this.memberAddForm.get("MembershipDate").value);
    }

    if (this.memberAddForm.get("EndDateOfTuition").value) {
      this.objForm.EndDateOfTuition = this.jalaliToMiladi(this.memberAddForm.get("EndDateOfTuition").value);
    }

    if (this.memberAddForm.get("EndDateOfInsurance").value) {
      this.objForm.EndDateOfInsurance = this.jalaliToMiladi(this.memberAddForm.get("EndDateOfInsurance").value);
    }

    if (this.memberAddForm.get("Password").value) {
      this.objForm.Password = this.memberAddForm.get("Password").value;
    }

    if (this.memberAddForm.get("Gender").value == false) {
      this.objForm.Gender = false;
      this.genderText = 'آقا';
    } else {
      this.objForm.Gender = true;
      this.genderText = 'بانو';
    }

    this.memberService.saveMembers(this.objForm).subscribe(
      result => {

        if (result['statusCode'] == 2022) {
          this.toastr.error('همنورد با کد ملی فوق قبلا ثبت شده است.', 'خطا', {
          });
          return;
        }

        this.memberAddForm.reset();
        this.genderText = 'آقا';
        this.enable = false;
        this.toastr.success(this.objForm.FirstName + ' ' + this.objForm.LastName + ' با موفقیت ذخیره شد.', 'موفقیت');
      },
      error => {
        //this.errors = error;
        this.toastr.error('ذخیره ' + this.objForm.FirstName + ' ' + this.objForm.LastName + ' با شکست مواجه شد.', 'خطا');
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      }
    );
  }

}
