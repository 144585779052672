import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Situation } from '../parameters/situation/situation.model';

@Injectable()
export class SituationService {

  constructor(private httpClient: HttpClient) { }

  situation: any;

  getSituations(): Observable<any> {
    return this.httpClient.get<any>('/api/situations')
      .pipe(map(res => res));
  }

  saveSituations(situation: Situation) {
    return this.httpClient.post<Situation>('/api/situations', situation).map(
      res => { res; }
    );
  }

  editSituation(id: number, situation: Situation) {
    return this.httpClient.put('/api/situations/' + id, situation)
      .pipe(map(res => res));
  }

  deleteSituation(id: number) {
    return this.httpClient.delete('/api/situations/' + id)
      .pipe(map(res => res));
  }

}
