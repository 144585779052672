import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Skill } from '../parameters/skill/skill.model';

@Injectable()
export class SkillService {

  constructor(private httpClient: HttpClient) { }

  skill: any;

  getSkills(): Observable<any> {
    return this.httpClient.get<any>('/api/skills')
      .pipe(map(res => res));
  }

  saveSkills(skill: Skill) {
    return this.httpClient.post<Skill>('/api/skills', skill).map(
      res => { res; }
    );
  }

  editSkill(id: number, skill: Skill) {
    return this.httpClient.put('/api/skills/' + id, skill)
      .pipe(map(res => res));
  }

  deleteSkill(id: number) {
    return this.httpClient.delete('/api/skills/' + id)
      .pipe(map(res => res));
  }

}
