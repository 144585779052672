import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from "rxjs/operators";

@Injectable()
export class RegisterService {

  constructor(private http: Http) { }

  member: any;

  getMembers() {
    return this.http.get('/api/members')
      .pipe(map(res => res.json()));
  }

  getMemberById(id: number) {
    return this.http.get('/api/members/' + id)
      .pipe(map(res => res.json()));
  }

  saveMembers(member: any) {
    return this.http.post('/api/members', member).map(
      res => res.json()
    );
  }

  editMember(id: number, member: any) {
    return this.http.put('/api/members/' + id, member)
      .pipe(map(res => res.json()));
  }

  deleteMember(id: number) {
    return this.http.delete('/api/members/' + id)
      .pipe(map(res => res.json()));
  }
}
