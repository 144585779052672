import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { MemberService } from "../../services/member.service";
import { Observable } from "rxjs";
import 'rxjs/add/observable/of';
import { Router } from '@angular/router';
import 'rxjs/add/observable/fromPromise';
import swal from 'sweetalert2';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private memberService: MemberService,
    private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.newIntercept(req, next);
  }

  private newIntercept(req: HttpRequest<any>, next: HttpHandler) {
    let currentUser = this.memberService.currentUserObject;
    let authReq = req;
    if (currentUser && currentUser.Token) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + currentUser.Token) });
    }
    return next.handle(authReq)
      .map(resp => {
        // on Response
        if (resp instanceof HttpResponse) {
          // Do whatever you want with the response.
          return resp;
        }
      }).catch(err => {
        // onError
        console.log(err);
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // redirect the user to login page
            // 401 unauthorised user
            this.memberService.logout();
            this.router.navigate(["/register/login"]);
          }

          if (err.status === 400) {
            if (err.error.message == 'Login or password is incorrect') {
              swal.fire({
                title: 'خطا',
                text: 'نام کاربری یا رمزعبور اشتباه می باشد!',
                type: 'warning',
              });
            }
          }
        }
        return Observable.of(err);
      });
  }
}
