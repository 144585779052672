import { PlanService } from '../../services/plan.service';
import { MembersPlanService } from '../../services/membersPlan.service';
import { ResponsibilitiesInPlanService } from '../../services/responsibilitiesInPlan.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MemberService } from '../../services/member.service';
import * as moment from 'jalali-moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Plan } from '../plan.model';
import { Role } from '../../member/member.model';
import { saveAs } from 'file-saver';
import swal from 'sweetalert2';

@Component({
  selector: 'app-plan-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})

export class PlanAssessmentComponent implements OnInit {
  _id = '';
  planAssessmentForm: FormGroup;
  enable = false;
  planTypes;
  objForm;
  messages;
  finalPlans: Plan[] = [];
  rows = [];
  loadingIndicator = true;
  reorderable = true;
  selectedPlan: any;
  selectedMember: any;
  selectedMembersPlans: any;
  responsibilitiesInPlanIdsSelected: any[] = [];
  responsibilitiesInPlan: any[] = [];
  planRate: number;
  averageScore: number = 0;
  precentOfScore: number = 0;
  checkAvalability = false;
  memberRole: string;
  currentUser: any;

  constructor(private formBuilder: FormBuilder,
    private planService: PlanService,
    private membersPlanService: MembersPlanService,
    private responsibilitiesInPlanService: ResponsibilitiesInPlanService,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private memberService: MemberService,
    private router: Router) {
  }

  ngOnInit() {
    this.currentUser = this.memberService.currentUserObject;
    this.memberRole = this.memberService.whatsRole();
    if (this.memberRole != Role.Admin && this.memberRole != Role.Supervisor) {
      this.memberService.logout();
    }
    this.getResponsibilitiesInPlan();
    this.createForm();
    this.route.params.subscribe(params => {
      this.getPlanById(params.id);
    });
  }

  createForm() {
    this.planAssessmentForm = this.formBuilder.group({
      Plans: new FormControl(),
      Members: new FormControl(),
      ResponsibilityInPlanIds: new FormControl(),
      EquipmentQuality: new FormControl(0),
      EnvironmentProtection: new FormControl(0),
      PhysicalState: new FormControl(0),
      Stamina: new FormControl(0),
      SkillAndTechnique: new FormControl(0),
      TeamWork: new FormControl(0),
      PublicEquipmentProtection: new FormControl(0),
      AverageScore: new FormControl(0),
      PrecentOfScore: new FormControl(0)
    });

    this.planAssessmentForm.controls['Plans'].disable();

    this.planAssessmentForm.get("EquipmentQuality").valueChanges.subscribe(selectedValue => {
      const avarage = (selectedValue +
        this.planAssessmentForm.get("EnvironmentProtection").value +
        this.planAssessmentForm.get("PhysicalState").value +
        this.planAssessmentForm.get("Stamina").value +
        this.planAssessmentForm.get("SkillAndTechnique").value +
        this.planAssessmentForm.get("TeamWork").value +
        this.planAssessmentForm.get("PublicEquipmentProtection").value) / 7;

      this.planAssessmentForm.controls['AverageScore'].setValue(avarage.toFixed(2));
      this.planAssessmentForm.controls['PrecentOfScore'].setValue(((avarage / this.planRate) * 100).toFixed(2));
    });

    this.planAssessmentForm.get("EnvironmentProtection").valueChanges.subscribe(selectedValue => {
      const avarage = (this.planAssessmentForm.get("EquipmentQuality").value +
        selectedValue +
        this.planAssessmentForm.get("PhysicalState").value +
        this.planAssessmentForm.get("Stamina").value +
        this.planAssessmentForm.get("SkillAndTechnique").value +
        this.planAssessmentForm.get("TeamWork").value +
        this.planAssessmentForm.get("PublicEquipmentProtection").value) / 7;

      this.planAssessmentForm.controls['AverageScore'].setValue(avarage.toFixed(2));
      this.planAssessmentForm.controls['PrecentOfScore'].setValue(((avarage / this.planRate) * 100).toFixed(2));
    });

    this.planAssessmentForm.get("PhysicalState").valueChanges.subscribe(selectedValue => {
      const avarage = (this.planAssessmentForm.get("EquipmentQuality").value +
        this.planAssessmentForm.get("EnvironmentProtection").value +
        selectedValue +
        this.planAssessmentForm.get("Stamina").value +
        this.planAssessmentForm.get("SkillAndTechnique").value +
        this.planAssessmentForm.get("TeamWork").value +
        this.planAssessmentForm.get("PublicEquipmentProtection").value) / 7;

      this.planAssessmentForm.controls['AverageScore'].setValue(avarage.toFixed(2));
      this.planAssessmentForm.controls['PrecentOfScore'].setValue(((avarage / this.planRate) * 100).toFixed(2));
    });

    this.planAssessmentForm.get("Stamina").valueChanges.subscribe(selectedValue => {
      const avarage = (this.planAssessmentForm.get("EquipmentQuality").value +
        this.planAssessmentForm.get("EnvironmentProtection").value +
        this.planAssessmentForm.get("PhysicalState").value +
        selectedValue +
        this.planAssessmentForm.get("SkillAndTechnique").value +
        this.planAssessmentForm.get("TeamWork").value +
        this.planAssessmentForm.get("PublicEquipmentProtection").value) / 7;

      this.planAssessmentForm.controls['AverageScore'].setValue(avarage.toFixed(2));
      this.planAssessmentForm.controls['PrecentOfScore'].setValue(((avarage / this.planRate) * 100).toFixed(2));
    });

    this.planAssessmentForm.get("SkillAndTechnique").valueChanges.subscribe(selectedValue => {
      const avarage = (this.planAssessmentForm.get("EquipmentQuality").value +
        this.planAssessmentForm.get("EnvironmentProtection").value +
        this.planAssessmentForm.get("PhysicalState").value +
        this.planAssessmentForm.get("Stamina").value +
        selectedValue +
        this.planAssessmentForm.get("TeamWork").value +
        this.planAssessmentForm.get("PublicEquipmentProtection").value) / 7;

      this.planAssessmentForm.controls['AverageScore'].setValue(avarage.toFixed(2));
      this.planAssessmentForm.controls['PrecentOfScore'].setValue(((avarage / this.planRate) * 100).toFixed(2));
    });

    this.planAssessmentForm.get("TeamWork").valueChanges.subscribe(selectedValue => {
      const avarage = (this.planAssessmentForm.get("EquipmentQuality").value +
        this.planAssessmentForm.get("EnvironmentProtection").value +
        this.planAssessmentForm.get("PhysicalState").value +
        this.planAssessmentForm.get("Stamina").value +
        this.planAssessmentForm.get("SkillAndTechnique").value +
        selectedValue +
        this.planAssessmentForm.get("PublicEquipmentProtection").value) / 7;

      this.planAssessmentForm.controls['AverageScore'].setValue(avarage.toFixed(2));
      this.planAssessmentForm.controls['PrecentOfScore'].setValue(((avarage / this.planRate) * 100).toFixed(2));
    });

    this.planAssessmentForm.get("PublicEquipmentProtection").valueChanges.subscribe(selectedValue => {
      const avarage = (this.planAssessmentForm.get("EquipmentQuality").value +
        this.planAssessmentForm.get("EnvironmentProtection").value +
        this.planAssessmentForm.get("PhysicalState").value +
        this.planAssessmentForm.get("Stamina").value +
        this.planAssessmentForm.get("SkillAndTechnique").value +
        this.planAssessmentForm.get("TeamWork").value +
        selectedValue) / 7;

      this.planAssessmentForm.controls['AverageScore'].setValue(avarage.toFixed(2));
      this.planAssessmentForm.controls['PrecentOfScore'].setValue(((avarage / this.planRate) * 100).toFixed(2));
    });
  }

  arrayToString(arrayObj: any) {
    var result = arrayObj.map(a => a.Id);
    return result.join();
  }

  jalaliToMiladi(date: any) {
    return moment(date, 'jYYYY/jMM/jDD HH:mm').format('YYYY-MM-DD HH:mm:ss');
  }

  convertToJSON(arrayObj: any) {
    return JSON.parse(arrayObj);
  }

  getPlanMembers() {

    this.rows = [];
    this.selectedMember = [];
    this.planRate = this.selectedPlan.PlanRate;
    this.planService.getPlanById(this.selectedPlan.Id).subscribe(
      x => {
        this.selectedMembersPlans = x;

        for (var i = 0; i < this.selectedMembersPlans.MembersPlans.length; i++) {
          this.rows.push(this.selectedMembersPlans.MembersPlans[i].Member);
        }

        this.rows = this.rows.map((i) => { i.FullName = i.FirstName + ' ' + i.LastName; return i; });
        this.planAssessmentForm.controls['ResponsibilityInPlanIds'].setValue([]);
        this.planAssessmentForm.controls['EquipmentQuality'].setValue(0);
        this.planAssessmentForm.controls['EnvironmentProtection'].setValue(0);
        this.planAssessmentForm.controls['PhysicalState'].setValue(0);
        this.planAssessmentForm.controls['Stamina'].setValue(0);
        this.planAssessmentForm.controls['SkillAndTechnique'].setValue(0);
        this.planAssessmentForm.controls['TeamWork'].setValue(0);
        this.planAssessmentForm.controls['PublicEquipmentProtection'].setValue(0);
        this.planAssessmentForm.controls['AverageScore'].setValue(0.00);
        this.planAssessmentForm.controls['PrecentOfScore'].setValue(0.00);
      }),
      error => {
        console.error('getFullPlans() got an error: ' + error);
      },
      () => {
        console.log('getFullPlans is done!');
        //this.getMembersByPlanId(planId);
      };
  }

  getMembersPlanItems() {
    this.responsibilitiesInPlanIdsSelected = [];
    const memberPlan = this.selectedMembersPlans.MembersPlans.filter(x => x.MemberId == this.selectedMember.Id)[0];

    if (memberPlan.ResponsibilityInPlanIds != "") {
      this.planAssessmentForm.controls['ResponsibilityInPlanIds'].setValue(this.getSelectedResponsibilities(memberPlan.ResponsibilityInPlanIds));
    } else {
      this.planAssessmentForm.controls['ResponsibilityInPlanIds'].setValue([]);
    }
    this.planAssessmentForm.controls['EquipmentQuality'].setValue(memberPlan.EquipmentQuality);
    this.planAssessmentForm.controls['EnvironmentProtection'].setValue(memberPlan.EnvironmentProtection);
    this.planAssessmentForm.controls['PhysicalState'].setValue(memberPlan.PhysicalState);
    this.planAssessmentForm.controls['Stamina'].setValue(memberPlan.Stamina);
    this.planAssessmentForm.controls['SkillAndTechnique'].setValue(memberPlan.SkillAndTechnique);
    this.planAssessmentForm.controls['TeamWork'].setValue(memberPlan.TeamWork);
    this.planAssessmentForm.controls['PublicEquipmentProtection'].setValue(memberPlan.PublicEquipmentProtection);

    const avarage = (this.planAssessmentForm.get("EquipmentQuality").value +
      this.planAssessmentForm.get("EnvironmentProtection").value +
      this.planAssessmentForm.get("PhysicalState").value +
      this.planAssessmentForm.get("Stamina").value +
      this.planAssessmentForm.get("SkillAndTechnique").value +
      this.planAssessmentForm.get("TeamWork").value +
      this.planAssessmentForm.get("PublicEquipmentProtection").value) / 7;

    this.planAssessmentForm.controls['AverageScore'].setValue(avarage.toFixed(2));
    this.planAssessmentForm.controls['PrecentOfScore'].setValue(((avarage / this.planRate) * 100).toFixed(2));

    this.averageScore = +avarage.toFixed(2);
    this.precentOfScore = +((avarage / this.planRate) * 100).toFixed(2);

  }

  getResponsibilitiesInPlan() {
    this.responsibilitiesInPlanService.getResponsibilitiesInPlan().subscribe(
      x => this.responsibilitiesInPlan = x
    );
  }

  getSelectedResponsibilities(ids: any) {
    for (var i = 0; i < ids.split(',').length; i++) {
      this.responsibilitiesInPlanIdsSelected.push(this.responsibilitiesInPlan.find(x => x.Id == Number(ids.split(',')[i])));
    }
    return this.responsibilitiesInPlanIdsSelected;
  }

  getPlans() {
    this.planService.getFullPlans(this.currentUser.Id).subscribe(
      x => {
        this.finalPlans = x;
      }),
      error => {
        console.error('getFullPlans() got an error: ' + error);
      },
      () => {
        console.log('getFullPlans is done!');
        //this.getMembersByPlanId(planId);
      };
  }

  getPlanById(id: number) {
    this.planService.getPlanById(id).subscribe((data: any) => {
      this._id = data.Id;
      this.selectedPlan = data;
      this.getPlanMembers();
    },
      err => console.error('getPlanById() got an error: ' + err));
  }

  goBack() {
    this.router.navigateByUrl('/plan/list');
  }

  assessmentPlan() {

    this.objForm = this.planAssessmentForm.getRawValue();

    if (this.planAssessmentForm.get("Plans").value) {
      this.objForm.PlanId = this.planAssessmentForm.get("Plans").value.Id;
    }

    if (this.planAssessmentForm.get("Members").value) {
      this.objForm.MemberId = this.planAssessmentForm.get("Members").value.Id;
    }

    if (this.planAssessmentForm.get("ResponsibilityInPlanIds").value) {
      this.objForm.ResponsibilityInPlanIds = this.arrayToString(this.planAssessmentForm.get("ResponsibilityInPlanIds").value);
    }

    if (this.planAssessmentForm.get("EquipmentQuality").value) {
      this.objForm.EquipmentQuality = +(this.planAssessmentForm.get("EquipmentQuality").value);
    }

    if (this.planAssessmentForm.get("EnvironmentProtection").value) {
      this.objForm.EnvironmentProtection = +(this.planAssessmentForm.get("EnvironmentProtection").value);
    }

    if (this.planAssessmentForm.get("PhysicalState").value) {
      this.objForm.PhysicalState = +(this.planAssessmentForm.get("PhysicalState").value);
    }

    if (this.planAssessmentForm.get("Stamina").value) {
      this.objForm.Stamina = +(this.planAssessmentForm.get("Stamina").value);
    }

    if (this.planAssessmentForm.get("SkillAndTechnique").value) {
      this.objForm.SkillAndTechnique = +(this.planAssessmentForm.get("SkillAndTechnique").value);
    }

    if (this.planAssessmentForm.get("TeamWork").value) {
      this.objForm.TeamWork = +(this.planAssessmentForm.get("TeamWork").value);
    }

    if (this.planAssessmentForm.get("PublicEquipmentProtection").value) {
      this.objForm.PublicEquipmentProtection = +(this.planAssessmentForm.get("PublicEquipmentProtection").value);
    }

    if (this.planAssessmentForm.get("AverageScore").value) {
      this.objForm.AverageScore = +(this.planAssessmentForm.get("AverageScore").value);
    }

    if (this.planAssessmentForm.get("PrecentOfScore").value) {
      this.objForm.PrecentOfScore = +(this.planAssessmentForm.get("PrecentOfScore").value);
    }

    this.route.params.subscribe(params => {
      //if (this._id) {
      //this.objForm.id = this._id;
      //}

      this.membersPlanService.assessmentMembersPlan(this.objForm).subscribe(
        result => {

          this.planAssessmentForm.reset();
          this.getPlanById(params.id);
          this.averageScore = 0;
          this.precentOfScore = 0;
          this.enable = false;
          this.toastr.success('ارزیابی ' + this.objForm.Members.FullName + ' با موفقیت انجام شد.', 'موفقیت');
          //this.router.navigateByUrl('/plan/list');
        },
        error => {
          //this.errors = error;
          this.toastr.error('ارزیابی ' + this.objForm.Members.FullName + ' با شکست مواجه شد.', 'خطا');
        },
        () => {
          // 'onCompleted' callback.
          // No errors, route to new page here
        }
      );
    });
  }

  downloadLicense() {
    if (!this.selectedPlan || this.selectedPlan.Id === 0) {
      this.toastr.error('لطفا ابتدا برنامه مورد نظر را انتخاب فرمایید!', 'خطا');
      return;
    }

    let current_datetime = new Date();
    let formatted_date = current_datetime.getFullYear() + "/" + (current_datetime.getMonth() + 1) + "/" + current_datetime.getDate();
    let now = moment(formatted_date, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD');

    this.planService.downloadLicense(this.selectedPlan.Id).subscribe(blob => {
      saveAs(blob, 'License-' + now + '.docx', {
        type: 'text/plain;charset=windows-1252',
        autoBom: true
      });
    });
  }

  downloadInsurance() {
    if (!this.selectedPlan || this.selectedPlan.Id === 0) {
      this.toastr.error('لطفا ابتدا برنامه مورد نظر را انتخاب فرمایید!', 'خطا');
      return;
    }

    let current_datetime = new Date();
    let formatted_date = current_datetime.getFullYear() + "/" + (current_datetime.getMonth() + 1) + "/" + current_datetime.getDate();
    let now = moment(formatted_date, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD');

    this.planService.downloadInsurance(this.selectedPlan.Id).subscribe(blob => {
      saveAs(blob, 'Insurance-' + now + '.docx', {
        type: 'text/plain;charset=windows-1252',
        autoBom: true
      });
    });
  }

  emailInsurance() {

    if (!this.selectedPlan || this.selectedPlan.Id === 0) {
      this.toastr.error('لطفا ابتدا برنامه مورد نظر را انتخاب فرمایید!', 'خطا');
      return;
    }

    let current_datetime = new Date();
    let formatted_date = current_datetime.getFullYear() + "/" + (current_datetime.getMonth() + 1) + "/" + current_datetime.getDate();
    let now = moment(formatted_date, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD');

    swal.fire({
      title: 'ارسال فهرست بیمه',
      text: "آیا از ارسال فهرست بیمه مطمین هستید؟",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0CC27E',
      cancelButtonColor: '#FF586B',
      confirmButtonText: 'بله',
      cancelButtonText: 'خیر',
      confirmButtonClass: 'btn btn-success btn-raised mr-5',
      cancelButtonClass: 'btn btn-danger btn-raised',
      buttonsStyling: false
    }).then((res) => {
      if (res.value) {
        this.planService.emailInsurance(this.selectedPlan.Id).subscribe(
          result => {
            this.toastr.success('ایمیل فهرست بیمه با موفقیت ارسال شد.', 'موفقیت');
          },
          error => {
            this.toastr.error('ارسال ایمیل فهرست بیمه با شکست مواجه شد.', 'خطا');
          },
          () => {
            // 'onCompleted' callback.
            // No errors, route to new page here
          }

        );
      } else {
        // handle cancel
      }

    })

  };
}


