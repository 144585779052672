/// <reference path="member/member.module.ts" />
/// <reference path="member/member.module.ts" />
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from "ngx-toastr";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpModule } from '@angular/http';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { MemberService } from './services/member.service';
import { PlanService } from './services/plan.service';
import { MembersPlanService } from './services/membersPlan.service';
import { DashboardService } from './services/dashboard.service';
import { RegisterService } from './services/register.service';
import { ResponsibilitiesInClubService } from './services/responsibilitiesInClub.service';
import { ResponsibilitiesInPlanService } from './services/responsibilitiesInPlan.service';
import { CertificationService } from './services/certification.service';
import { PlanTypesService } from './services/planTypes.service';
import { SituationService } from './services/situation.service';
import { SkillService } from './services/skill.service';

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { DragulaService } from "ng2-dragula";
import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";

import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MemberModule } from './member/member.module';
import { RegisterModule } from './register/register.module';
import { PlanModule } from './plan/plan.module';
import { ParametersModule } from './parameters/parameters.module';
import { JwtInterceptor } from './shared/tools/jwt-interceptor';
import { SearchPipe } from './shared/pipes/search.pipe';
import { FileUploadModule } from 'ng2-file-upload';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    SearchPipe
  ],
  imports: [
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    HttpModule,
    ToastrModule.forRoot(),
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "YOUR KEY"
    }),
    PerfectScrollbarModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MemberModule,
    RegisterModule,
    PlanModule,
    ParametersModule,
    NgxDatatableModule,
    FileUploadModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    MemberService,
    PlanService,
    MembersPlanService,
    ResponsibilitiesInClubService,
    ResponsibilitiesInPlanService,
    CertificationService,
    SituationService,
    SkillService,
    DashboardService,
    PlanTypesService,
    RegisterService,
    DragulaService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
